<div class="container h-100 wider-modal" >
  <div class="row p-2 h-100">
    <!-- Left Section: Cost Input Form -->
    <div class="col-md-7 col-sm-12">
      <div class="card h-100">
        <div class="card-body">
          <h2 class="mb-3">Total Costs of: {{ item.productInfo?.name }}</h2>
          <hr />
          <form [formGroup]="costForm">
            <!-- Material Cost -->
            <!-- <div class="form-group mb-3">
              <label for="totalMaterialCost">Material Cost:</label>
              <input
                type="number"
                id="totalMaterialCost"
                formControlName="totalMaterialCost"
                class="form-control"
                placeholder="Enter material cost"
                min="0"
              />
              <div
                *ngIf="
                  costForm.get('totalMaterialCost')?.hasError('required') &&
                  costForm.get('totalMaterialCost')?.touched
                "
                class="text-danger"
              >
                Material Cost is required.
              </div>
            </div> -->
            <div class="form-group mb-3">
              <label for="totalMaterialCost">Material Cost:</label>
              <p class="form-control-static">
                {{ getTotalMaterialCost(data.item.components) | currency: 'INR' }}
              </p>
            </div>

            <!-- Glass Cost -->
            <div class="form-group mb-3">
              <label for="glassCost">Glass Cost:</label>
              <input
                type="number"
                id="glassCost"
                formControlName="glassCost"
                class="form-control"
                placeholder="Enter glass cost"
                min="0"
              />
              <div
                *ngIf="
                  costForm.get('glassCost')?.hasError('required') &&
                  costForm.get('glassCost')?.touched
                "
                class="text-danger"
              >
                Glass Cost is required.
              </div>
            </div>

            <!-- Labour Cost -->
            <div class="form-group mb-3">
              <label for="labourCost">Labour Cost:</label>
              <input
                type="number"
                id="labourCost"
                formControlName="labourCost"
                class="form-control"
                placeholder="Enter labour cost"
                min="0"
              />
              <div
                *ngIf="
                  costForm.get('labourCost')?.hasError('required') &&
                  costForm.get('labourCost')?.touched
                "
                class="text-danger"
              >
                Labour Cost is required.
              </div>
            </div>

            <!-- A&OH Charges -->
            <div class="form-group mb-3">
              <label for="expensePercentageAH">A&OH Charges (%):</label>
              <input
                type="number"
                id="expensePercentageAH"
                formControlName="expensePercentageAH"
                class="form-control"
                placeholder="Enter A&OH charges"
                min="0"
                max="100"
              />
              <div
                *ngIf="
                  costForm.get('expensePercentageAH')?.hasError('required') &&
                  costForm.get('expensePercentageAH')?.touched
                "
                class="text-danger"
              >
                A&OH is required.
              </div>
            </div>

            <!-- Profit Factor -->
            <div class="form-group mb-3">
              <label for="expensePercentageAE">Profit Factor (%):</label>
              <input
                type="number"
                id="expensePercentageAE"
                formControlName="expensePercentageAE"
                class="form-control"
                placeholder="Enter profit factor"
                min="0"
                max="100"
              />
              <div
                *ngIf="
                  costForm.get('expensePercentageAE')?.hasError('required') &&
                  costForm.get('expensePercentageAE')?.touched
                "
                class="text-danger"
              >
                Profit Factor is required.
              </div>
            </div>
          </form>

          <!-- Calculated Costs -->
          <div class="mt-3">
            <p>
              <strong>Calculated Expense:</strong>
              {{ calculatedExpense | currency : "INR" }}
            </p>
            <p><strong>Sub Total:</strong> {{ subtotal | currency : "INR" }}</p>
            <p><strong>GST 18%:</strong> {{ gstCost | currency : "INR" }}</p>
            <p>
              <strong>Total Cost:</strong> {{ totalCost | currency : "INR" }}
            </p>
          </div>

          <!-- Action Buttons -->
          <div class="d-flex justify-content-end mt-3">
            <button class="btn btn-secondary me-2" (click)="closeModal()">
              Close
            </button>
            <button
              class="btn btn-primary"
              (click)="saveCosts()"
              [disabled]="!costForm.valid"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Right Section: Component Details -->
    <div class="col-md-5 col-sm-12">
      <div class="card">
        <div class="card-body">
          <h5>Item Components</h5>
          <div
            class="scrollable-container"
            style="
              max-height: 600px;
              overflow-y: auto;
              border: 1px solid #ddd;
              padding: 10px;
            "
          >
            <div
              *ngIf="data.item?.components && data.item.components.length > 0"
            >
              <div *ngFor="let component of data.item.components">
                <h6 class="h7 mb-1">
                  Category: <strong>{{ component.product?.Category }}</strong>
                </h6>
                <h6 class="h7 mb-1">
                  Product Name:
                  <strong>{{ component.product?.SubCategory }}</strong>
                </h6>
                <h6 class="h7 mb-1">
                  Description:
                  <strong>{{ component.product?.Description }}</strong>
                </h6>
                <h6 class="text-secondary h7 mb-1">
                  Qty: <strong>{{ component.count }}</strong>
                </h6>
                <h6 class="h7 mb-1">
                  Cost : 
                  <strong>{{ component.rate| currency: "INR" }}</strong>
                </h6>
                <h6 class="h7 mb-1">
                  Total Cost: 
                  <strong>{{ component.count * component.rate| currency: "INR" }}</strong>
                </h6>
                <hr />
              </div>
              <h6 class="text-color-primary">
                Total Material Cost:
                <strong>
                  {{ getTotalMaterialCost(data.item.components) | currency: "INR" }}
                </strong>
              </h6>
            </div>
            <div
              *ngIf="
                !data.item?.components || data.item.components.length === 0
              "
            >
              <img
                src="./assets/images/no-data.png"
                class="img-fluid"
                alt="no data"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
