import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiEndPoints } from 'src/app/core/constants';
import { SearchmodalComponent } from '../searchmodal/searchmodal.component';
import { ApiHelper } from 'src/app/core/service/api.helper';
import { AddComponentModalComponent } from '../addcomponentmodal/addcomponentmodal.component';
import { WorkPlanCreateModalComponent } from '../../work-plan-create-modal/work-plan-create-modal.component';
import { ToastrService } from 'ngx-toastr';
import { DownloadQuotesPdfComponent } from '../download-quotes-pdf/download-quotes-pdf.component';
import { CostModalComponent } from '../cost-view/cost-view.component';

@Component({
  selector: 'app-quotes-view',
  templateUrl: './quotes-view.component.html',
  styleUrls: ['./quotes-view.component.scss'],
})
export class QuotesViewComponent implements OnInit {
  searchForm: FormGroup;
  projectDetails: any = null;
  selectedItem: any = null;
  quote: any = {
    totalCost: 0,
    items: [],
  };

  quoteId!: string;
  quoteSumbited: boolean = false;
  quoteCreated: boolean = false;
  quoteApproved: boolean = false;

  revisionId!: string;
  isQtyChanged!: boolean;
  jobId!: string;
  isWidthChanged!: boolean;
  isHeightChanged!: boolean;

  allComponets: any[] = [];
  editingComponent: any = null;
  tempCount: number = 0;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    public apiHelper: ApiHelper,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.searchForm = this.fb.group({
      searchTerm: [''],
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const id = params.get('id');
      this.quoteId = id || '';
      if (id) {
        this.getQuoteDetails(id);
        this.getAllComponetsOfQuote(id);
       
      
       
      }
    });
  }

  isObject(value: any): boolean {
    return typeof value === 'object';
  }

  getQuoteDetails(id: string) {
    this.apiHelper
      .post({ id: id }, ApiEndPoints.getQuoteDetails)
      .subscribe((response: any) => {
        if (response && response.data) {
          this.quote = response.data.quoteDetails;
          this.quote.items = response.data.quoteinfoDetails;
          this.quote.items.forEach(
            (item: {
              isQtyChanged: boolean;
              isWidthChanged: boolean;
              isHeightChanged: boolean;
            }) => {
              item.isQtyChanged = false;
              item.isWidthChanged = false;
              item.isHeightChanged = false;
              this.updateSquareFeet(item);
            }
          );
          this.getProjectDetails(this.quote.projectId);
          if (this.quote.status === 'submitted') {
            this.quoteSumbited = true;
          }
          if (this.quote.status === 'created') {
            this.quoteCreated = true;
          }
          if (this.quote.status === 'approved') {
            this.quoteApproved = true;
          }
          if (this.quoteApproved) {
            this.apiHelper
              .post({ id: this.quote.id }, ApiEndPoints.getJobData)
              .subscribe((response: any) => {
                if (response && response.data) {
                  this.jobId = response.data;
                  this.router.navigate(['/job-view', this.jobId]);
                }
              });
          }
        }
      });
  }

  getProjectDetails(projectId: string) {
    this.apiHelper
      .post({ id: projectId }, ApiEndPoints.getProjectDetails)
      .subscribe((response: any) => {
        if (response && response.data) {
          this.projectDetails = response.data.projectDetails;
        }
      });
  }
  updateSquareFeet(item: any) {
    if (item.width && item.height && item.qty) {
      item.totalSquareFeet =
        (item.width / 1000) * (item.height / 1000) * item.qty * 10.764;
    } else {
      item.totalSquareFeet = 0;
    }
  }

  selectItem(item: any) {
    this.selectedItem = item;
   

    if (this.selectedItem && this.selectedItem.id) {
      const quoteListId = this.selectedItem.id;
      

      this.apiHelper
        .post({ quoteListId: quoteListId }, ApiEndPoints.getItemComponent)
        .subscribe(
          (response: any) => {
            if (response && response.data) {
              this.selectedItem.components = response.data;
            } else {
              this.toastr.error('Failed to fetch components.');
            }
          },
          (error) => {
            this.toastr.error('An error occurred while fetching components.');
          }
        );
    }
  }

  deleteComponent(component: any) {
    if (confirm('Are you sure you want to delete this component?')) {
      this.apiHelper
        .post({ id: component.id }, ApiEndPoints.deleteComponent)
        .subscribe({
          next: (response: any) => {
         
            if (response && (response.message === 'deleteComponent' || response.success)) {
           
              if (this.selectedItem && this.selectedItem.components) {
                this.selectedItem.components = this.selectedItem.components.filter(
                  (c: any) => c.id !== component.id
                );
              }
            
              this.getAllComponetsOfQuote(this.quoteId);
             
              this.toastr.success('Component deleted successfully.');
            } else {
              console.warn('Unexpected response format:', response);
            
              if (this.selectedItem && this.selectedItem.components) {
                this.selectedItem.components = this.selectedItem.components.filter(
                  (c: any) => c.id !== component.id
                );
                this.getAllComponetsOfQuote(this.quoteId);
                this.toastr.success('Component deleted successfully.');
              } else {
                this.toastr.error('Failed to delete the component.');
              }
            }
          },
          error: (error) => {
            console.error('Delete component error:', error);
            this.toastr.error('An error occurred while deleting the component.');
          }
        });
    }
  }
  updateItemQuantity(item: any) {
    if (!item.qty) {
      this.toastr.error('Quantity is required');
      return;
    }
    if (!item.width) {
      this.toastr.error('Width is required');
      return;
    }
    if (!item.height) {
      this.toastr.error('Height is required');
      return;
    }
    const updatedItem = {
      id: item.id,
      qty: item.qty,
      width: item.width,
      height: item.height,
      totalSquareFeet: item.totalSquareFeet,
    };
    this.apiHelper.post(updatedItem, ApiEndPoints.quoteListUpdate).subscribe(
      (response: any) => {
        if (response && response.data) {
          this.toastr.success('Items updated successfully!');
          item.isQtyChanged = false;
          item.isWidthChanged = false;
          item.isHeightChanged = false;
          // this.updateSquareFeet(item);
        } else {
          this.toastr.error('Failed to update itemms.');
        }
      },
      (error) => {
        this.toastr.error('An error occurred while updating items.');
      }
    );
  }

  openSearchDialog() {
    const dialogRef = this.dialog.open(SearchmodalComponent, {
      width: '1000px',
      height: '500px',
      data: {
        searchTerm: this.searchForm.get('searchTerm')?.value,
        selectedItems: this.quote.items,
        quoteId: this.quote.id,
        type: 'quotesView',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.selectedItems) {
        result.selectedItems.forEach((item: any) => {
          this.quote.items.push(item);
        });
      }
    });
  }

  openPdfModal() {
    const dialogRef = this.dialog.open(DownloadQuotesPdfComponent, {
      width: '10000px',
      height: '500px',
      data: { quoteItems: this.quote.items, 
        quoteDetails: this.quote,
        type: true },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.toastr.success('Pdf Download Succefully');
      }
    });
  }
  openPdfModalForComponets() {
    // this.getAllComponetsOfQuote(this.quoteId);
    const dialogRef = this.dialog.open(DownloadQuotesPdfComponent, {
      width: '10000px',
      height: '500px',
      data: {
        allComponets: this.allComponets,
        quoteDetails: this.quote,
        types: true,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.toastr.success('Pdf Download Succefully');
      }
    });
  }
  openAddComponentDialog() {
    if (!this.selectedItem) {
      alert('Please select an item to add components.');
      return;
    }

    const dialogRef = this.dialog.open(AddComponentModalComponent, {
      width: '10000px',
      height: '500px',
      data: {
        itemId: this.selectedItem.id,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.selectedComponents) {
     
      }


      this.apiHelper
        .post(
          {
            quoteListId: this.selectedItem.id,
            components: result.selectedComponents,
            entityId: this.quoteId,
          },

          ApiEndPoints.saveItemComponent
        )
        .subscribe(
          (response: any) => {
            if (response && response.data) {
              this.toastr.success('Component added and saved successfully!');
              this.selectedItem.components.push(...response.data);
              this.getAllComponetsOfQuote(this.quoteId);
            } else {
              this.toastr.error('Failed to save component.');
            }
          },
          (error) => {
            this.toastr.error('An error occurred while saving the component.');
          }
        );
    });
  }

  updateStatusSubmit(id: string) {
    const data = {
      id: id,
      status: 'submitted',
    };
    this.apiHelper
      .post(data, ApiEndPoints.quoteUpdate, true)
      .subscribe((response) => {
        if (response && response.data) {
          this.toastr.success('Quote is Submit to Client');
          window.location.reload();
        } else {
          this.toastr.error('Error...!');
        }
      });
  }

  // createAndSaveRevision() {
  //   const data = {
  //     quoteId: this.quoteId,
  //   };

  //   this.apiHelper.post(data, ApiEndPoints.revisionCreate, true).subscribe(
  //     (response) => {
  //       if (response && response.data) {
  //         console.log('res',response.data);
  //         this.revisionId = response.data.reviewDetails.id;
  //         const itemsWithComponents = this.quote.items.map((item: {
  //           id: string;
  //           components?: Array<any>;
  //           [key: string]: any;
  //         }) => {
           
  //           return {
  //             ...item,
  //             components: Array.isArray(item.components) ? item.components : []
  //           };
  //         });

  //         const revisionListData = {
  //           quoteId: this.quoteId,
  //           reviewListDetails: itemsWithComponents,
           
  //           reviewId: this.revisionId,
          
  //         };
  //         this.apiHelper
  //           .post(revisionListData, ApiEndPoints.revisionListCreate, true)
  //           .subscribe(
  //             (response) => {
  //               if (response && response.data) {
  //               this.quote.items.forEach((item: any) => {
  //                 const itemComponents = response.data.itemComponentListDetails.filter(
  //                   (component: any) =>
  //                     component.quoteListId === item.id // Match by `quoteListId`
  //                 );
  //                 item.components = itemComponents; // Attach the components
  //               });

  //                 this.router.navigate(['/revision-view', this.revisionId]);
  //                 this.toastr.success(
  //                   'Revision Quotes Items added and saved successfully!'
  //                 );
  //               } else {
  //                 this.toastr.error('Failed to save Revision Items.');
  //               }
  //             },
  //             (error: any) => {
  //               this.toastr.error(
  //                 'An error occurred while saving the Revision Items.'
  //               );
  //             }
  //           );
  //       } else {
  //         this.toastr.error('Failed to create RevisionQuotes.');
  //       }
  //     },
  //     (error: any) => {
  //       this.toastr.error(
  //         'An error occurred while creating the RevisionQuotes.'
  //       );
  //     }
  //   );
  // }
  async createAndSaveRevision() {
    try {
      const data = {
        quoteId: this.quoteId,
      };

      const revisionResponse = await this.apiHelper
        .post(data, ApiEndPoints.revisionCreate, true)
        .toPromise();

      if (revisionResponse && revisionResponse.data) {
        this.revisionId = revisionResponse.data.reviewDetails.id;

        const itemsWithComponentsPromises = this.quote.items.map(async (item: any) => {
          const componentResponse = await this.apiHelper
            .post({ quoteListId: item.id }, ApiEndPoints.getItemComponent)
            .toPromise();
          
          return {
            ...item,
            components: componentResponse?.data || []
          };
        });

        const itemsWithComponents = await Promise.all(itemsWithComponentsPromises);

        const revisionListData = {
          quoteId: this.quoteId,
          reviewListDetails: itemsWithComponents,
          reviewId: this.revisionId
        };

        const revisionListResponse = await this.apiHelper
          .post(revisionListData, ApiEndPoints.revisionListCreate, true)
          .toPromise();

        if (revisionListResponse && revisionListResponse.data) {
          this.quote.items = this.quote.items.map((item: any) => {
            const itemComponents = revisionListResponse.data.itemComponentListDetails.filter(
              (component: any) => component.quoteListId === item.id
            );
            return {
              ...item,
              components: itemComponents
            };
          });

          this.router.navigate(['/revision-view', this.revisionId]);
          this.toastr.success('Revision Quote created successfully with all items and components!');
        } else {
          this.toastr.error('Failed to save Revision Items.');
        }
      } else {
        this.toastr.error('Failed to create Revision Quote.');
      }
    } catch (error) {
      console.error('Error creating revision:', error);
      this.toastr.error('An error occurred while creating the Revision Quote.');
    }
  }

  confirmJob(id: string) {
    const data = {
      id,
      status: 'approved',
    };

    this.apiHelper
      .post(data, ApiEndPoints.quoteUpdate, true)
      .subscribe((response) => {
        if (response && response.data) {
          this.toastr.success('Job Confirmed');
          this.createJobFromQuoteV2(id);
        }
      });
  }

  // createJobFromQuote(id: string) {
  //   const data = { id, entityType: 'quote' };

  //   this.apiHelper.post(data, ApiEndPoints.createJobFromQuote, true).subscribe(
  //     (response) => {
  //       if (response && response.data) {
  //         this.jobId = response.data.id; // Store the jobId after successful creation
  //         const revisionListData = {
  //           quoteId: this.quoteId,
  //           jobItems: this.quote.items,
  //           jobId: this.jobId,
  //         };

  //         // Now, create job items only after the job has been created successfully
  //         this.apiHelper
  //           .post(revisionListData, ApiEndPoints.createJobItems, true)
  //           .subscribe(
  //             (response) => {
  //               if (response && response.data) {
  //                 this.router.navigate(['/job-view', this.jobId]); // Navigate to the job view page
  //                 this.toastr.success(
  //                   'Job Items added and saved successfully!'
  //                 );
  //               } else {
  //                 this.toastr.error('Failed to save Job Items.');
  //               }
  //             },
  //             (error) => {
  //               this.toastr.error(
  //                 'An error occurred while saving the Job Items.'
  //               );
  //             }
  //           );

  //         this.toastr.success('Job Created from Quote');
  //       } else {
  //         this.toastr.error('Failed to create job from quote');
  //       }
  //     },
  //     (error) => {
  //       this.toastr.error('An error occurred while creating the job.');
  //     }
  //   );
  // }
  // createJobFromQuoteV2(id: string) {
  //   const data = { id, entityType: 'quote' };

  //   this.apiHelper.post(data, ApiEndPoints.createJobFromQuote, true).subscribe(
  //     (response) => {
  //       if (response && response.data) {
  //         this.jobId = response.data.id; // Store the jobId after successful creation
  //         const jobListData = {
  //           quoteId: this.quoteId,
  //           jobItems: this.quote.items,
  //           jobId: this.jobId,
  //         };

  //         // Now, create job items only after the job has been created successfully
  //         this.apiHelper
  //           .post(jobListData, ApiEndPoints.createJobItems, true)
  //           .subscribe(
  //             (response) => {
  //               if (response && response.data) {
  //                 this.router.navigate(['/job-view', this.jobId]); // Navigate to the job view page
  //                 this.toastr.success(
  //                   'Job Items added and saved successfully!'
  //                 );
  //               } else {
  //                 this.toastr.error('Failed to save Job Items.');
  //               }
  //             },
  //             (error) => {
  //               this.toastr.error(
  //                 'An error occurred while saving the Job Items.'
  //               );
  //             }
  //           );

  //         this.toastr.success('Job Created from Quote');
  //       } else {
  //         this.toastr.error('Failed to create job from quote');
  //       }
  //     },
  //     (error) => {
  //       this.toastr.error('An error occurred while creating the job.');
  //     }
  //   );
  // }


  async createJobFromQuoteV2(id: string) {
    try {
 
      const data = {
        id,
        entityType: "quote"
      };
  
  
      const jobResponse = await this.apiHelper
        .post(data, ApiEndPoints.createJobFromQuote, true)
        .toPromise();
  
      if (jobResponse && jobResponse.data) {
        const newJobId = jobResponse.data.id;
  
   
        const itemsWithComponentsPromises = this.quote.items.map(async (item: any) => {
          const componentResponse = await this.apiHelper
            .post({ quoteListId: item.id }, ApiEndPoints.getItemComponent)
            .toPromise();
          
          return {
            ...item,
            components: componentResponse?.data || []
          };
        });
  

        const itemsWithComponents = await Promise.all(itemsWithComponentsPromises);

        const jobListData = {
          quoteId: this.quoteId,
          jobItems: itemsWithComponents,
          jobId: newJobId
        };
  
    
        const jobItemsResponse = await this.apiHelper
          .post(jobListData, ApiEndPoints.createJobItems, true)
          .toPromise();
  
        if (jobItemsResponse && jobItemsResponse.data) {

          this.jobId = newJobId;
          this.toastr.success('Job created successfully with all items and components!');
          

          this.router.navigate(['/job-view', newJobId]);
        } else {
          throw new Error('Failed to create job items');
        }
      } else {
        throw new Error('Failed to create job from quote');
      }
    } catch (error) {
      console.error('Error creating job:', error);
      this.toastr.error(error instanceof Error ? error.message : 'An error occurred while creating the job');
    }
  }
  previewsQuotes() {
    this.router.navigate(['/quote-revision-view', this.quoteId]);
  }

  onInputChange(event: any, item: any) {
    const originalQty = event.target.value;
    const originalWidth = event.target.value;
    const originalHeight = event.target.value;

    if (
      originalQty !== item.qty &&
      originalWidth !== item.width &&
      originalHeight !== item.height
    ) {
      item.isQtyChanged = true;
      item.isWidthChanged = true;
      item.isHeightChanged = true;
    } else {
      item.isQtyChanged = false;
      item.isWidthChanged = false;
      item.isHeightChanged = false;
    }
    this.updateSquareFeet(item);
  }

  deleteItem(id: string) {
    const components = this.selectedItem?.components || [];
    this.apiHelper.post({ id ,components}, ApiEndPoints.quoteItemDelete, true).subscribe(
      (response: any) => {
        if (response && response.data) {
          this.getAllComponetsOfQuote(this.quoteId);
          this.toastr.success('Item deleted successfully!');
          this.quote.items = this.quote.items.filter(
            (item: any) => item.id !== id
          );
          if (this.selectedItem && this.selectedItem.id === id) {
            this.selectedItem = null;
          }
        } else {
          this.toastr.error('Failed to delete the item.');
        }
      },
      (error) => {
        this.toastr.error('An error occurred while deleting the item.');
      }
    );
  }

  confirmDelete(itemId: string) {
    if (confirm('Are you sure you want to delete this item?')) {
      this.deleteItem(itemId);
    }
  }
  // unwanted
  openWorkPlanCreate() {
    const dialogRef = this.dialog.open(WorkPlanCreateModalComponent, {
      width: '600px',
      data: {
        items: this.quote.items,
        quoteListId: this.quote.id,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.toastr.success('Work Plan Created!');
      }
    });
  }
  //   openCostModal(item:any) {

  //     const dialogRef = this.dialog.open(CostModalComponent, {
  //       width: '500px',
  //       data: {
  //         totalMaterialCost:0 ,
  //         glassCost: 0,
  //         labourCost: 0,
  //         expensePercentage: 0,
  //         item : item
  //       },

  //     });
  //     dialogRef.afterClosed().subscribe((result) => {
  //       if (result && result.totalCost) {
  //         // this.quote.totalCost = result.totalCost;
  //        item.totalCost = result.totalCost;
  //       }
  //     });
  //   }

  // }
  startEditComponent(component: any) {
    this.editingComponent = component;
    this.tempCount = component.count;
  }
  
  cancelEdit() {
    this.editingComponent = null;
  }
  
  updateItemComponent(component: any) {
    if (!this.tempCount || this.tempCount < 0) {
      this.toastr.error('Please enter a valid quantity');
      return;
    }
  
    const updateData = {
      id: component.id,
      count: Number(this.tempCount) 
    };
  
  
    this.apiHelper.post(updateData, ApiEndPoints.updateItemComponent).subscribe({
      next: (response: any) => {
        if (response && response.data && response.data.success) {
 
          component.count = this.tempCount;
          this.toastr.success('Component updated successfully'); 
          this.editingComponent = null;
          this.getAllComponetsOfQuote(this.quoteId);
        } else {
          console.error('API response error:', response);  
          this.toastr.error(response?.data?.message || 'Failed to update component'); 
        }
      }
      
  });
  }
  
  openCostModal(item: any) {
    const dialogRef = this.dialog.open(CostModalComponent, {
      width: '1000px',
      height: '600px',
      data: {
        item: item,
        totalCost: item.totalCost,
        totalMaterialCost: item.totalMaterialCost,
        totalLaborCost: item.totalLaborCost,
        totalGlassCost: item.totalGlassCost,
        aOhFactor: item.aOhFactor,
        profitFactor: item.profitFactor,
        gstCost: item.gstCost,
        subTotal: item.subTotal,
        calculatedExpense: item.calculatedExpense,
      }
    
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        item.totalCost = result.totalCost;
        item.totalMaterialCost = result.totalMaterialCost;
        item.totalLaborCost = result.totalLaborCost;
        item.totalGlassCost = result.totalGlassCost;
        item.aOhFactor = result.aOhFactor;
        item.profitFactor = result.profitFactor;
        item.gstCost = result.gstCost;
        item.subTotal = result.subTotal;
        item.calculatedExpense = result.calculatedExpense;

        this.saveCostDetails(item);
      }
    });
  }

  saveCostDetails(item: any) {
    this.apiHelper.post(item, ApiEndPoints.quoteListUpdate).subscribe(
      (response: any) => {
        if (response && response.data) {
          this.toastr.success('Costs saved successfully!');
        } else {
          this.toastr.error('Failed to save costs.');
        }
      },
      (error) => {
        this.toastr.error('An error occurred while saving costs.');
      }
    );
  }
  getTotalMaterialCost(components: any[]): number {
    return components.reduce((total, comp) => total + comp.count *comp.rate , 0);
  }

  getAllComponetsOfQuote(id: string) {
    this.apiHelper
      .post({ entityId: id }, ApiEndPoints.getAllComponentsEntity, true)
      .subscribe((response) => {
        if (response.data) {
          this.allComponets = response.data;
        }
      });
  }
}
