import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';
import { PaginationService } from 'src/app/core/service/pagination.service';

declare var bootstrap: any;

// interface ProjectData {
//   id: number;
//   name: string;
//   clientInfo: {
//     name: string;
//     phone: string;
//     location: string;
//   };
//   planner: {
//     name: string;
//     phone: string;
//     type: string;
//     location: string;
//   };
//   location: string;
//   isActive: string;
//   createdAt?: Date;
// }

@Component({
  selector: 'app-user-projects',
  templateUrl: './user-projects.component.html',
  styleUrls: ['./user-projects.component.scss']
})
export class UserProjectsComponent implements OnInit {
  createProjectForm: FormGroup;
  projectData: any[] = [];
  filteredProjectData: any[] = [];
  clients: any[] = [];
  builders: any[] = [];
  consultant : any[]=[];
  contractor : any[] = [];
  location: any[] = [];
  status: any[] = [];
  searchTerm: string = '';
  currentPage: number = 1;
  itemsPerPage: number = 10;
  totalPages: number = 1;


 
  selectedFilters = {
    architect: null as string | null,
    contractor: null as string | null,
    consultant: null as string | null,
    location: null as string | null,
    status: null as string | null,
    client: null as string | null 
  };
  filterSearch = {
    architect: '',
    contractor: '',
    consultant: '',
    location: '',
    status: '',
    client:'',
  
  };
  activeFilter: 'architect' | 'contractor' | 'consultant' |'location' | 'status' |'client'| null = null;

  constructor(
    public apiHelper: ApiHelper,
    private router: Router,
    public toastr: ToastrService,
    private fb: FormBuilder
  ) {
    this.createProjectForm = this.fb.group({
      name: ['', Validators.required],
      clientInfo: ['', Validators.required],
      planner: [''],
      consultant : [''],
      contractor : [''],
      location: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.getProjects();
    this.getAllClients();
    this.getAllBuilders();
    this.getAllConsultant();
    this.getAllContractor();
  }

  getProjects(): void {
    this.apiHelper.post({}, ApiEndPoints.allProjects).subscribe((response) => {
      if (response && response.data) {
        this.projectData = response.data;
        this.filterProjects();
      } else {
        this.toastr.error('Failed to load projects.');
      }
    });
  }

  getAllClients(): void {
    this.apiHelper.post({}, ApiEndPoints.getAllClients).subscribe((response) => {
      if (response && response.data) {
        this.clients = response.data;
      } else {
        this.toastr.error('Failed to load clients.');
      }
    });
  }

  getAllBuilders(): void {
    this.apiHelper.post({type:'Architect'}, ApiEndPoints.getAllBuilders).subscribe((response) => {
      if (response && response.data) {
        this.builders = response.data;
      } else {
        this.toastr.error('Failed to load Architects.');
      }
    });
  }
  getAllContractor(): void {
    this.apiHelper.post({type:'Contractor'}, ApiEndPoints.getAllBuilders).subscribe((response) => {
      if (response && response.data) {
        this.contractor = response.data;
      } else {
        this.toastr.error('Failed to load Contractors.');
      }
    });
  }
  getAllConsultant(): void {
    this.apiHelper.post({type:'Consultant'}, ApiEndPoints.getAllBuilders).subscribe((response) => {
      if (response && response.data) {
        this.consultant = response.data;
      } else {
        this.toastr.error('Failed to load Consultants.');
      }
    });
  }
  
  showData(id: string): void {
    this.router.navigate(['/project-details', id]);
  }

  submitProject(): void {
    if (this.createProjectForm.valid) {
      const newProject = this.createProjectForm.value;
      this.apiHelper.post(newProject, ApiEndPoints.projectCreate).subscribe((response) => {
        if (response && response.data) {
          this.toastr.success('Project created successfully!');
          this.projectData.push({
            ...newProject,
            clientInfo: this.clients.find(client => client.id === newProject.clientId),
            builderInfo: this.builders.find(builder => builder.id === newProject.builderId),
            isActive: 'active',
            createdAt: new Date(),
          });
          this.filterProjects(); // Re-filter with the new project added
          this.closeModal();
          this.createProjectForm.reset();
        } else {
          this.toastr.error('Failed to create project.');
        }
      });
    } else {
      this.toastr.warning('Please fill in all the required fields.');
    }
  }

  submitProjectV2(): void {
    if (this.createProjectForm.valid) {
      const newProject = this.createProjectForm.value;
      this.apiHelper.post(newProject, ApiEndPoints.projectCreate).subscribe((response) => {
        if (response && response.data) {
          this.toastr.success('Project created successfully!');
          this.projectData.unshift(response.data);
          this.filterProjects(); // Re-filter with the new project added
          this.closeModal();
          this.createProjectForm.reset();
        } else {
          this.toastr.error('Failed to create project.');
        }
      });
    } else {
      this.toastr.warning('Please fill in all the required fields.');
    }
  }

  closeModal(): void {
    const modalElement = document.getElementById('createProjectModal');
    if (modalElement) {
      const modalInstance = bootstrap.Modal.getInstance(modalElement);
      if (modalInstance) {
        modalInstance.hide();
      }
    }
  }

  searchProjects(): void {
    this.currentPage =1
    this.filterProjects();
  }

  onPageChange(page: number): void {
    this.currentPage = page;
    this.filterProjects();
  }

  
  filterProjects(): void {
    let filtered = [...this.projectData];
  
    if (this.searchTerm.trim()) {
      filtered = filtered.filter(item =>
        this.customFilterFn(this.searchTerm, item.name)
      );
      filtered.sort((a, b) => this.customSortFn(this.searchTerm, a.name, b.name));
    }
 
    if (this.selectedFilters.architect) {
      filtered = filtered.filter(item =>
        item.planner && item.planner.name === this.selectedFilters.architect
      );
    }
  
    if (this.selectedFilters.contractor) {
      filtered = filtered.filter(item =>
        item.contractor && item.contractor.name === this.selectedFilters.contractor
      );
    }
  
    if (this.selectedFilters.consultant) {
      filtered = filtered.filter(item =>
        item.consultant && item.consultant.name === this.selectedFilters.consultant
      );
    }
    if (this.selectedFilters.client) {
      filtered = filtered.filter(item =>
        item.clientInfo && item.clientInfo.name === this.selectedFilters.client
      );
    }
    if (this.selectedFilters.location) {
      filtered = filtered.filter(item =>
        item.location === this.selectedFilters.location
      );
    }
  
   
    if (this.selectedFilters.status) {
      filtered = filtered.filter(item =>
        item.isActive === this.selectedFilters.status
      );
    }
  
    this.totalPages = Math.ceil(filtered.length / this.itemsPerPage);
    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = this.currentPage * this.itemsPerPage;
    this.filteredProjectData = filtered.slice(start,end);
  }
  customSortFn(searchTerm: string, a: string, b: string): number {
    const aStartsWith = a.toLowerCase().startsWith(searchTerm);
    const bStartsWith = b.toLowerCase().startsWith(searchTerm);

    if (aStartsWith && !bStartsWith) {
      return -1; // 'a' comes first
    }
    if (!aStartsWith && bStartsWith) {
      return 1; // 'b' comes first
    }
    return a.localeCompare(b);
  }

  customFilterFn(searchTerm: string, item: string): boolean {
    return item.toLowerCase().includes(searchTerm);
  }
  
  getUniquePersons(role: 'architect' | 'contractor' | 'consultant'): any[] {
    if (!this.projectData) return [];
  
    const roleMapping: { [key: string]: any[] } = {
      'architect': this.builders,
      'contractor': this.contractor,
      'consultant': this.consultant
    };
  
    const persons = roleMapping[role] || [];
    
   
    const uniquePersons = new Map(
      persons.map(person => [person.name, person])
    );
  
    return Array.from(uniquePersons.values());
  }

   getUniqueLocations(): string[] {
    return [...new Set(
      this.projectData
        .map(project => project.location)
        .filter(location => location)
    )].sort();
  }
  

  getUniqueStatuses(): string[] {
    return [...new Set(
      this.projectData
        .map(project => project.isActive)
        .filter(status => status) 
    )].sort();
  }

  getUniqueClients(): any[] {
   
    const uniqueClientsMap = new Map();
    
    this.projectData
      .filter(project => project.clientInfo)
      .forEach(project => {
        uniqueClientsMap.set(project.clientInfo.name, project.clientInfo);
      });
    
    return Array.from(uniqueClientsMap.values())
      .sort((a, b) => a.name.localeCompare(b.name));
  }
 toggleFilter(filterType: 'architect' | 'contractor' | 'consultant' | 'location' | 'status'|'client', event: Event): void {
    event.stopPropagation();
    this.activeFilter = this.activeFilter === filterType ? null : filterType;
    
    
    if (this.activeFilter) {
      setTimeout(() => {
        const closeFilter = (e: MouseEvent) => {
          const target = e.target as HTMLElement;
          if (!target.closest('.filter-content')) {
            this.activeFilter = null;
            document.removeEventListener('click', closeFilter);
          }
        };
        document.addEventListener('click', closeFilter);
      });
    }
  }

 
  getFilteredOptions(type: 'architect' | 'contractor' | 'consultant' | 'location' | 'status'|'client'): any[] {
    const searchTerm = this.filterSearch[type].toLowerCase();
    
    switch(type) {
      case 'architect':
      case 'contractor':
      case 'consultant':
        const persons = this.getUniquePersons(type);
        return searchTerm 
          ? persons.filter(option => option.name.toLowerCase().includes(searchTerm))
          : persons;
          case 'client':
            const clients = this.getUniqueClients();
            return searchTerm
              ? clients.filter(client => client.name.toLowerCase().includes(searchTerm))
              : clients;

      case 'location':
        const locations = this.getUniqueLocations();
        return searchTerm
          ? locations.filter(location => location.toLowerCase().includes(searchTerm))
          : locations;
      
      case 'status':
        const statuses = this.getUniqueStatuses();
        return searchTerm
          ? statuses.filter(status => status.toLowerCase().includes(searchTerm))
          : statuses;
      
      default:
        return [];
    }
  }
  clearAllFilters(): void {
    this.selectedFilters = {
      architect: null,
      contractor: null,
      consultant: null,
      location: null,
      status: null,
      client: null
    };
    this.filterSearch = {
      architect: '',
      contractor: '',
      consultant: '',
        location: '',
      status: '',
      client: ''
    };
    this.searchTerm = '';
    this.currentPage = 1;
    this.filterProjects();
  }

  applyFilter(type: 'architect' | 'contractor' | 'consultant'|'location'|'status'|'client', value: string | null): void {
    this.selectedFilters[type] = value;
    this.activeFilter = null;
    this.filterSearch[type] = '';
    this.currentPage = 1;
    this.filterProjects();
  }

}
