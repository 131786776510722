import { Component, OnInit } from '@angular/core';
import { ApiHelper } from 'src/app/core/service/api.helper';
import { ApiEndPoints } from 'src/app/core/constants';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UpdateSupplierComponent } from '../update-supplier/update-supplier.component';

@Component({
  selector: 'app-component-supplier-view',
  templateUrl: './component-supplier-view.component.html',
  styleUrls: ['./component-supplier-view.component.scss'],
})
export class ComponentSupplierViewComponent implements OnInit {
  groupedComponents: any[] = [];
  filteredComponents: any[] = [];
  paginatedComponents: any[] = [];
  searchTerm: string = '';

  selectedSupplierDetails: { [key: string]: any } = {};

  currentPage: number = 1;
  itemsPerPage: number = 8;
  totalPages: number = 1;

  constructor(
    private apiHelper: ApiHelper,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private router: Router
  ) { }

  redirectToAddSupplier(): void {
    this.router.navigate(['/componentsupplier']);
  }

  ngOnInit(): void {
    this.loadComponentSuppliers();
  }

  loadComponentSuppliers(): void {
    this.apiHelper.post({}, ApiEndPoints.getAllComponentSuppliers).subscribe(
      (response) => {
        if (response && response.data) {
          this.groupComponents(response.data);
          this.updatePagination(response.data.length); 
          this.filteredComponents = [...this.groupedComponents];
          this.paginate();
        } else {
          this.toastr.error('No data found in the response.');
        }
      },
      (error) => {
        this.toastr.error('Error occurred while fetching component suppliers.');
        console.error('Error:', error);
      }
    );
  }

  groupComponents(suppliers: any[]): void {
    const grouped: any = {};

    suppliers.forEach((supplier) => {
      if (supplier.componentDetails) {
        const componentId = supplier.componentDetails.id;

        if (!grouped[componentId]) {
          grouped[componentId] = {
            id: componentId,
            name: supplier.componentDetails.name,
            suppliers: [],
          };
          this.selectedSupplierDetails[componentId] = {
            moq: 0,
            rate: 0,
            days: 0,
            supplierId: '',
          };
        }

        grouped[componentId].suppliers.push({
          ...supplier,
          id: supplier.id,
        });
      }
    });

    this.groupedComponents = Object.values(grouped);
  }

  onSupplierChange(event: Event, componentId: string): void {
    const supplierId = (event.target as HTMLSelectElement).value;

    const selectedComponent = this.groupedComponents.find(
      (component) => component.id === componentId
    );

    if (selectedComponent) {
      const selectedSupplier = selectedComponent.suppliers.find(
        (supplier:any) => supplier.id === supplierId
      );

      if (selectedSupplier) {
        this.selectedSupplierDetails[componentId] = {
          moq: selectedSupplier.moq,
          rate: selectedSupplier.rate,
          days: selectedSupplier.days,
          supplierId: selectedSupplier.id,
        };
      }
    }
  }

  openUpdateSupplierModal(
    componentId: string,
    supplierId: string | undefined
  ): void {
    if (!supplierId) {
      this.toastr.error('Please select a supplier first.');
      return;
    }

    const dialogRef = this.dialog.open(UpdateSupplierComponent, {
      width: '800px',
      height: '600px',
      data: {
        componentId: componentId,
        supplierId: supplierId,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loadComponentSuppliers();
      }
    });
  }

  searchItems(): void {
    const searchTermLower = this.searchTerm.toLowerCase();

    const filtered = this.groupedComponents.filter((component) =>
      this.customFilterFn(searchTermLower, component.name)
    );

    this.filteredComponents = filtered.sort((a, b) =>
      this.customSortFn(searchTermLower, a.name, b.name)
    );
    this.updatePagination(this.filteredComponents.length);
    this.paginate();
  }

  customSortFn(searchTerm: string, a: string, b: string): number {
    const aStartsWith = a.toLowerCase().startsWith(searchTerm);
    const bStartsWith = b.toLowerCase().startsWith(searchTerm);

    if (aStartsWith && !bStartsWith) {
      return -1; // 'a' comes first
    }
    if (!aStartsWith && bStartsWith) {
      return 1; // 'b' comes first
    }
    return a.localeCompare(b);
  }

  customFilterFn(searchTerm: string, item: string): boolean {
    return item.toLowerCase().includes(searchTerm);
  }
  updatePagination(totalItems: number): void {
    this.totalPages = Math.ceil(totalItems / this.itemsPerPage);
    this.paginate();
  }
  

  paginate(): void {
    const dataToPaginate = this.searchTerm ? this.filteredComponents : this.groupedComponents;

    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;

  
    this.paginatedComponents = dataToPaginate.slice(start, end);
  }

  onPageChange(page: number): void {
    this.currentPage = page;
    this.paginate();
  }
}