<ng-container *ngIf="isObject(job)">
    <div class="row">
      <div class="col-md-8 col-sm-12 mb-3">
        <h4 class="text-app-primary2">Job Name: {{ job?.jobName }}</h4>
        <div class="card-wrap bg-white">
          <div class="table-responsive">
            <table class="table table-hover table-border table-common">
              <thead class="thead-light">
                <tr>
                  <th>Sl.No</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Quantity</th>
                  <th>Width(mm)</th>
                  <th>Height(mm)</th>
                  <th>Total sq.ft</th>
                  <th>Total cost</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let jobItem of jobItems; let i = index"(click)="selectItem(jobItem)">
                  <td>{{ i + 1 }}</td>
                  <td>{{ jobItem.productInfo?.name || "N/A" }}</td>
                  <td>{{ jobItem.description || "N/A" }}</td>
                  <td>{{ jobItem.qty || "N/A" }}</td>
                  <td>{{ jobItem.width || "N/A" }}</td>
                  <td>{{ jobItem.height || "N/A" }}</td>
                  <td>{{ jobItem.totalSquareFeet | number : '1.5-5' }}</td>
                  <td>{{ jobItem.totalCost | currency : 'INR' }}</td>

                </tr>
              </tbody>
            </table>
            <div class="d-flex justify-content-end mb-3">
              <button class="btn brand-btn btn-success btn-sm me-2" (click)="openPdfModal()">
                Export Options
              </button>
            
            
              <button
                  class="btn btn-primary  btn-sm me-2"
                  (click)="createWorkPlan()"
                >
                  Create Work Plan
                </button>
                <button class="btn btn-secondary btn-sm me-2" (click)="viewWorkPlan()">
                  View Work Plan
                </button>
                
            </div>
          </div>
        </div>
      </div>
      
      <div class="col-md-4 col-sm-12">
        <h4 class="mb-3 text-app-primary2">Project Details</h4>
        <div class="mb-3" *ngIf="projectDetails">
          <div class="card-wrap bg-white">
            <div class="project-details">
              <div class="client-details">
                <h5 class="text-app-primary">Client Details</h5>
                <p class="mb-1">
                  <strong>Project Name:</strong>
                  {{ projectDetails.name || "N/A" }}
                </p>
                <p class="mb-1">
                  <strong>Name:</strong>
                  {{ projectDetails.clientInfo.name || "N/A" }}
                </p>
                <p class="mb-1">
                  <strong>Address:</strong>
                  {{ projectDetails.clientInfo.location || "N/A" }}
                </p>
                <p class="mb-1">
                  <strong>Contact:</strong>
                  {{ projectDetails.clientInfo.phone || "N/A" }}
                </p>
              </div>
            </div>
          </div>
        </div>
        
        <!-- <div>
          <h5 class="mb-3 text-app-primary2">Item Components</h5>
          <div class="card-wrap bg-white">
            <div *ngIf="selectedItem; else noItemSelected">
              <div class="component-details pt-2" *ngIf="selectedItem.components && selectedItem.components.length > 0">
                <ul>
                  <li *ngFor="let component of selectedItem.components">
                    <p><strong>Category:</strong> {{ component.product?.Category }}</p>
                    <p><strong>Product Name:</strong> {{ component.product?.SubCategory }}</p>
                    <p><strong>Description:</strong> {{ component.product?.Description }}</p>
                    <p><strong>Quantity:</strong> {{ component?.count }}</p>
                  </li>
                </ul>
              </div>
              <div *ngIf="!selectedItem.components || selectedItem.components.length === 0">
                <img src="./assets/images/no-data.png" class="img-fluid" alt="no data file" />
              </div>
            </div>
            <ng-template #noItemSelected>
              <p>Please click on an item to view its components.</p>
              <img src="./assets/images/no-data.png" class="img-fluid" alt="no data file" />
            </ng-template> -->
            <div>
              <h5 class="mb-3 text-app-primary2">Item Components</h5>
      
              <div class="card-wrap bg-white">
                <div *ngIf="selectedItem; else noItemSelected">
                  <!-- <button
                        class="btn btn-primary mt-3"
                        (click)="openAddComponentDialog()"
                      >
                        Add Component
                      </button> -->
      
                  <div
                    class="component-details pt-2"
                    *ngIf="
                      selectedItem.components && selectedItem.components.length > 0
                    "
                  >
                    <!-- <ul>
                          <li *ngFor="let component of selectedItem.components">
                            <p><strong>Category:</strong> {{  component.product?.Category  }}</p>
                            <p><strong>Product Name:</strong> {{ component.product?.SubCategory  }}</p>
                            <p><strong>Description:</strong> {{  component.product?.Description }}</p>
                            <p><strong>Quantity:</strong> {{  component?.count }}</p>
                                   </li>
                        </ul> -->
                    <div class="scrollbar h-200">
                      <div *ngFor="let component of selectedItem.components">
                        <div
                          class="d-flex justify-content-between align-items-center"
                        >
                          <h6 class="h7 mb-1 p-0">
                            Category:<strong>{{
                              component.product?.Category
                            }}</strong>
                          </h6>
                          <h6 class="text-color-secondary h7 mb-1 p-0">
                            Qty: <strong>{{ component?.count }}</strong>
                          </h6>
                        </div>
      
                        <h6 class="h7 mb-1 p-0">
                          Product Name:
                          <strong> {{ component.product?.SubCategory }}</strong>
                        </h6>
                        <h6 class="h7 mb-1 p-0">
                          Description:
                          <strong>{{ component.product?.Description }} </strong>
                        </h6>
                        <h6 class="h7 mb-1 p-0">
                          Cost:
                          <strong>{{ component?.rate | currency : "INR" }}</strong>
                        </h6>
                        <h6 class="h7 mb-1 p-0">
                          Total Component Cost:
                          <strong>{{
                            component?.count * component?.rate | currency : "INR"
                          }}</strong>
                        </h6>
      
                        <!-- Updated quantity check -->
                        <hr />
                      </div>
                    </div>
                    <h6 class="text-color-primary">
                      Total Material Cost:
                      <strong>
                        {{
                          getTotalMaterialCost(selectedItem.components)
                            | currency : "INR"
                        }}
                      </strong>
                    </h6>
                  </div>
      
                  <div
                    *ngIf="
                      !selectedItem.components || selectedItem.components.length === 0
                    "
                  >
                    <img
                      src="./assets/images/no-data.png"
                      class="img-fluid"
                      alt="no data file"
                    />
                  </div>
                </div>
      
                <ng-template #noItemSelected>
                  <p>Please click on an item to view its components.</p>
                  <img
                    src="./assets/images/no-data.png"
                    class="img-fluid"
                    alt="no data file"
                  />
                </ng-template>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  