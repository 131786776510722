import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiEndPoints } from 'src/app/core/constants';
import { SearchmodalComponent } from '../searchmodal/searchmodal.component';
import { ApiHelper } from 'src/app/core/service/api.helper';
import { AddComponentModalComponent } from '../addcomponentmodal/addcomponentmodal.component';
import { WorkPlanCreateModalComponent } from '../../work-plan-create-modal/work-plan-create-modal.component';
import { ToastrService } from 'ngx-toastr';
import { DownloadQuotesPdfComponent } from '../download-quotes-pdf/download-quotes-pdf.component';
import { CostModalComponent } from '../cost-view/cost-view.component'

@Component({
  selector: 'app-revision-view',
  templateUrl: './revision-view.component.html',
  styleUrls: ['./revision-view.component.scss'],
})
export class RevisionViewComponent implements OnInit {
  searchForm: FormGroup;
  projectDetails: any = null;
  selectedItem: any = null;

  reviewId!: string;
  // review: any;
  review:any = {
    totalCost:0,
    items :[]
  };
  revisionSumbited: boolean = false;
  revisionCreated: boolean = false;
  revisionApproved: boolean = false;
  execute: boolean = true;
  isQtyChanged!: boolean;
  isWidthChanged!: boolean;
  isHeightChanged!: boolean;
  allComponets: any[] = [];
  allComponents: any;
  jobId: any;
  editingComponent: any = null;
  tempCount: number = 0;
  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    public apiHelper: ApiHelper,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.searchForm = this.fb.group({
      searchTerm: [''],
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const id = params.get('id');
      this.reviewId = id || '';
      if (id) {
        this.getReviewListDetails(id);
        this.getAllComponetsOfQuote(id)
      }
    });
  }

  isObject(value: any): boolean {
    return typeof value === 'object';
  }
  // getReviewListDetails(id: string) {
  // console.log(id)
  //   this.apiHelper
  //     .post({ id: id }, ApiEndPoints.revisionListGet)
  //     .subscribe((response: any) => {
  //       if (response && response.data) {
  //         this.review = response.data.reviewDetails;
  //         console.log('review',response.data.reviewDetails.quoteId)
  //         this.review.items = response.data.reviewListDetails;
  //         this.allComponents = [];
  //         // this.review.items.forEach((item: { componentsList: any[] }) => {
  //         //   item.componentsList.forEach((component) => {
  //         //     this.allComponents.push(component);
  //         //   });
  //         // });
          
  //         if (Array.isArray(this.review.items)) {
  //           this.review.items.forEach((item: any) => {
  //             if (item?.componentsList && Array.isArray(item.componentsList)) {
  //               item.componentsList.forEach((component: any) => {
  //                 if (component) {
  //                   this.allComponents.push(component);
  //                 }
  //               });
  //         this.review.items.forEach((item: { isQtyChanged: boolean,isWidthChanged:boolean,isHeightChanged:boolean  }) => {
  //           item.isQtyChanged = false;
  //           item.isWidthChanged = false;
  //           item.isHeightChanged = false;
  //           this.updateSquareFeet(item);
  //         })}});
  // getReviewListDetails(id: string) {
  //   this.apiHelper
  //     .post({ id: id }, ApiEndPoints.revisionListGet)
  //     .subscribe((response: any) => {
  //       if (response && response.data) {
  //         this.review = response.data.reviewDetails;
  //         this.review.items = response.data.reviewListDetails;
          
  //         // Store all components that have itemsId
  //         if (response.data.itemComponentListDetails) {
  //           this.allComponets = response.data.itemComponentListDetails.filter(
  //             (component: any) => component.itemsId !== null
  //           );
  //         }

  //         // Rest of your existing code...
  //         if (Array.isArray(this.review.items)) {
  //           this.review.items.forEach((item: any) => {
  //             item.isQtyChanged = false;
  //             item.isWidthChanged = false;
  //             item.isHeightChanged = false;
  //             this.updateSquareFeet(item);
  //           });
          
  //         this.getProjectDetails(this.review.projectId);
  //         if (this.review.status === 'revisionSubmitted') {
  //           this.revisionSumbited = true;
  //         }
  //         if (this.review.status === 'revisionCreated') {
  //           this.revisionCreated = true;
  //         }
  //         if (this.review.status === 'revisionApproved') {
  //           this.revisionApproved = true;
  //         }
  //         if (this.revisionApproved) {
  //           this.apiHelper
  //           .post({ id: this.review.id }, ApiEndPoints.getRevisionJob)
  //           .subscribe((response: any) => {
  //             if (response && response.data) {
  //               this.jobId = response.data;
  //               this.router.navigate(['/job-view', this.jobId]);
  //             }
  //           });
  //         }
  //       }
  //     }});
      
  // }
  getReviewListDetails(id: string) {
    this.apiHelper
      .post({ id: id }, ApiEndPoints.revisionListGet)
      .subscribe((response: any) => {
        if (response && response.data) {
          console.log('review',response.data)
          this.review = response.data.reviewDetails;
          const revisionId = response.data.reviewDetails.id;
          const quoteId = response.data.reviewDetails.quoteId;
          
        
          this.apiHelper
            .post({ quoteId: quoteId,revisionId:revisionId }, ApiEndPoints.revisionListItem)
            .subscribe((itemsResponse: any) => {
              if (itemsResponse && itemsResponse.data) {
                
                this.review.items = itemsResponse.data;
                
             
                if (Array.isArray(this.review.items)) {
                  this.review.items.forEach((item: any) => {
                    item.isQtyChanged = false;
                    item.isWidthChanged = false;
                    item.isHeightChanged = false;
                    this.updateSquareFeet(item);
                  });
                }
              }
            });
  
         
          if (this.review.status === 'revisionSubmitted') {
            this.revisionSumbited = true;
          }
          if (this.review.status === 'revisionCreated') {
            this.revisionCreated = true;
          }
          if (this.review.status === 'revisionApproved') {
            this.revisionApproved = true;
          }
          if (this.revisionApproved) {
            this.apiHelper
            .post({ id: this.review.id }, ApiEndPoints.getRevisionJob)
            .subscribe((response: any) => {
              if (response && response.data) {
                this.jobId = response.data;
                this.router.navigate(['/job-view', this.jobId]);
              }
            });}

this.getProjectDetails(this.review.projectId);


}});
}
  
  getProjectDetails(projectId: string) {
    this.apiHelper
      .post({ id: projectId }, ApiEndPoints.getProjectDetails)
      .subscribe((response: any) => {
        if (response && response.data) {
          this.projectDetails = response.data.projectDetails;
        }
      });
  }
  updateSquareFeet(item: any) {
    if (item.width && item.height && item.qty) {
      item.totalSquareFeet =(((( item.width)/1000)*((item.height)/1000))*item.qty)*10.764
    } else {
      item.totalSquareFeet = 0;
    }
  }


  // selectItem(item: any) {
  //   this.selectedItem = item;
   
  
  //   // Now, make an API call to fetch components for the selected item
  //   if (this.selectedItem && this.selectedItem.id) {
  //     if (this.selectedItem.componentsList && this.selectedItem.componentsList.length > 0) {
  //       this.selectedItem.components = this.selectedItem.componentsList;
        
  //     } else {
  //       console.log('reve',this.selectedItem.components)
  //     const quoteId = this.selectedItem.quoteId;
  //     // console.log(this.selectedItem.componentsList)
      
  //     // API call to fetch components for the selected item (assuming the endpoint is getItemComponents)
  //     this.apiHelper
  //       .post({ quoteId: quoteId }, ApiEndPoints.getItemComponents)
  //       .subscribe((response: any) => {
  //         if (response && response.data) {
           
  //           // Store the components in selectedItem
  //           this.selectedItem.components = response.data;
  //         } else {
  //           this.toastr.error('Failed to fetch components.');
  //         }
  //       }, (error) => {
  //         this.toastr.error('An error occurred while fetching components.');
  //       });
  //   }
  // }}
  
  selectItem(item: any) {
    this.selectedItem = item;
  
    
    if (this.selectedItem && this.selectedItem.id) {
      this.apiHelper
        .post({ id: this.selectedItem.id }, ApiEndPoints.getItemComponents)
        .subscribe((response: any) => {
          if (response && response.data) {
            this.selectedItem.components = response.data;
          }
        });
    }
  }
  updateItemQuantity(item: any) {
    if (!item.qty) {
      this.toastr.error('Quantity is required');
      return;
    }
    if (!item.width) {
      this.toastr.error('Width is required');
      return;
    }
    if (!item.height ) {
      this.toastr.error('Height is required');
      return;
    }
    const updatedItem = { id: item.id, qty: item.qty ,width: item.width,height: item.height, totalSquareFeet: item.totalSquareFeet};
    this.apiHelper.post(updatedItem, ApiEndPoints.revisionListUpdate).subscribe(
      (response: any) => {
        if (response && response.data) {
          this.toastr.success('Item updated successfully!');
          item.isQtyChanged = false;
          item.isWidthChanged = false;
          item.isHeightChanged = false;
        } else {
          this.toastr.error('Failed to update item.');
        }
      },
      (error) => {
        this.toastr.error('An error occurred while updating item.');
      }
    );
  }

  openSearchDialog() {
    const dialogRef = this.dialog.open(SearchmodalComponent, {
      width: '1000px',
      height: '500px',
      data: {
        searchTerm: this.searchForm.get('searchTerm')?.value,
        selectedItems: this.review.items,
        reviewId: this.review.id,
        id: this.review.quoteId,
        type: 'reviewQuotesView',
      },
    
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.selectedItems) {
        result.selectedItems.forEach((item: any) => {
          this.review.items.push(item);
        });
      }
    });
  }
  openPdfModal() {
    const dialogRef = this.dialog.open(DownloadQuotesPdfComponent, {
      width: '10000px',
      height: '500px',
      data: {
        quoteItems: this.review.items,
        quoteDetails : this.review,
        type: true
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.toastr.success('Pdf Downloaded!');
      }
    });
  }
  openPdfModalForComponets() {
    const dialogRef = this.dialog.open(DownloadQuotesPdfComponent, {
      width: '10000px',
      height: '500px',
      data: {
        allComponets: this.allComponets,
        quoteDetails : this.review,
        types: true,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.toastr.success('Pdf Downloaded!');
      }
    });
  }
  openAddComponentDialog() {
    if (!this.selectedItem) {
      alert('Please select an item to add components.');
      return;
    }
  
    const dialogRef = this.dialog.open(AddComponentModalComponent, {
      width: '10000px',
      height: '500px',
      data: {
        itemId: this.selectedItem.id, // This is the quoteListId we're interested in
      },
    });
  
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.selectedComponents) {
       
    
        // Use selectedItem.id (quoteListId) in the payload
        this.apiHelper.post(
          { 
            quoteListId: this.selectedItem.id, // Pass quoteListId here
            components: result.selectedComponents,
            entityId : this.selectedItem.revisionId
          },
          ApiEndPoints.saveItemComponent
        ).subscribe(
          (response: any) => {
            if (response && response.data) {
             
              this.toastr.success('Component added and saved successfully!');
              this.selectedItem.components.push(...response.data);
              this.getAllComponetsOfQuote(this.reviewId);
            } else {
              this.toastr.error('Failed to save component.');
            }
          },
          (error) => {
            this.toastr.error('An error occurred while saving the component.');
          }
        );
      }
    });
  }
  
  // createAndSaveRevision() {
  //   const data = {
  //     quoteId: this.review.quoteId,
      
  //   };

  //   this.apiHelper.post(data, ApiEndPoints.revisionCreate, true).subscribe(
  //     (response) => {
  //       if (response && response.data) {
          
  //         const newRevisionId = response.data.reviewDetails.id;
  //         const revisionListData = {
  //           quoteId: this.review.quoteId,
  //           reviewListDetails: this.review.items,
  //           reviewId: newRevisionId,
  //         };
  //         this.apiHelper
  //           .post(revisionListData, ApiEndPoints.revisionListCreate, true)
  //           .subscribe((response) => {
  //             if (response && response.data) {
  //               this.review.items = [];
  //               this.review = {};
  //               this.revisionSumbited = false;
  //               this.router.navigate(['/revision-view', newRevisionId]);
  //             }
  //           });
  //       } else {
  //         this.toastr.error('Failed to create RevisisonQuotes.');
  //       }
  //     },
  //     (error: any) => {
  //       this.toastr.error(
  //         'An error occurred while creating the RevisionQuotes.'
  //       );
  //     }
  //   );
  // }
  async createAndSaveRevision() {
    try {

      const data = {
        quoteId: this.review.quoteId,
      };

      const revisionResponse = await this.apiHelper
        .post(data, ApiEndPoints.revisionCreate, true)
        .toPromise();
  
      if (revisionResponse && revisionResponse.data) {
        const newRevisionId = revisionResponse.data.reviewDetails.id;
  
        const itemsWithComponentsPromises = this.review.items.map(async (item: any) => {
          const componentResponse = await this.apiHelper
            .post({ id: item.id }, ApiEndPoints.getItemComponents)
            .toPromise();
  
          return {
            ...item,
            components: componentResponse?.data || []
          };
        });
  
        const itemsWithComponents = await Promise.all(itemsWithComponentsPromises);
  
        const revisionListData = {
          quoteId: this.review.quoteId,
          reviewListDetails: itemsWithComponents,
          reviewId: newRevisionId
        };
  
        const revisionListResponse = await this.apiHelper
          .post(revisionListData, ApiEndPoints.revisionListCreate, true)
          .toPromise();
  
        if (revisionListResponse && revisionListResponse.data) {
          this.review.items = this.review.items.map((item: any) => {
            const itemComponents = revisionListResponse.data.itemComponentListDetails?.filter(
              (component: any) => component.itemsId === item.id
            ) || [];
  
            return {
              ...item,
              components: itemComponents
            };
          });
          this.review = {};
          this.revisionSumbited = false;

          this.router.navigate(['/revision-view', newRevisionId]);
          this.toastr.success('New revision created successfully with all items and components!');
        } else {
          throw new Error('Failed to create revision list');
        }
      } else {
        throw new Error('Failed to create revision');
      }
    } catch (error) {
      console.error('Error creating revision:', error);
      this.toastr.error(error instanceof Error ? error.message : 'An error occurred while creating the revision');
    }
  }
  updateStatusSubmit(id: string) {
    const data = {
      id: id,
      status: 'revisionSubmitted',
    };
    this.apiHelper
      .post(data, ApiEndPoints.reviewUpdate, true)
      .subscribe((response) => {
        if (response && response.data) {
          this.toastr.success('Quote is Submit to Client');
          window.location.reload();
        } else {
          this.toastr.error('Error...!');
        }
      });
  }

  confirmJob(id: string) {
    const data = {
      id,
      status: 'revisionApproved',
    };
    this.apiHelper
      .post(data, ApiEndPoints.reviewUpdate, true)
      .subscribe((response) => {
        if (response && response.data) {
          this.toastr.success('Job Confirmed');
         this.createJobFromRevisionV2(id)
        }
      });
  }
  // createJobFromRevision(id: string) {
  //   const data = { id, entityType: "revision" };
  
  //   this.apiHelper
  //     .post(data, ApiEndPoints.createJobFromRevision, true)
  //     .subscribe(
  //       (response) => {
  //         if (response && response.data) {
  //           this.jobId = response.data.id;  // Store the jobId after successful creation
  //           const revisionListData = {
  //             reviewId: this.reviewId,
  //             jobItems: this.review.items,
  //             jobId: this.jobId,
  //           };
  
  //           // Now, create job items only after the job has been created successfully
  //           this.apiHelper
  //             .post(revisionListData, ApiEndPoints.createJobItems, true)
  //             .subscribe(
  //               (response) => {
  //                 if (response && response.data) {
  //                   this.router.navigate(['/job-view', this.jobId]);  // Navigate to the job view page
  //                   this.toastr.success('Job Items added and saved successfully!');
  //                 } else {
  //                   this.toastr.error('Failed to save Job Items.');
  //                 }
  //               },
  //               (error) => {
  //                 this.toastr.error('An error occurred while saving the Job Items.');
  //               }
  //             );
  
  //           this.toastr.success('Job Created from Quote');
  //           // window.location.reload(); // Reload after creating the job
  //         } else {
  //           this.toastr.error('Failed to create job from quote');
  //         }
  //       },
  //       (error) => {
  //         this.toastr.error('An error occurred while creating the job.');
  //       }
  //     );
  // }
  // createJobFromRevisionV2(id: string) {
  //   const data = { id,
  //      entityType : "revision"
  //    };
  
  //   this.apiHelper
  //     .post(data, ApiEndPoints.createJobFromRevision, true)
  //     .subscribe(
  //       (response) => {
  //         if (response && response.data) {
  //           this.jobId = response.data.id;  // Store the jobId after successful creation
  //           const jobListData = {
  //             reviewId: this.reviewId,
  //             jobItems: this.review.items,
  //             jobId: this.jobId,
  //           };
  
  //           // Now, create job items only after the job has been created successfully
  //           this.apiHelper
  //             .post(jobListData, ApiEndPoints.createJobItems, true)
  //             .subscribe(
  //               (response) => {
  //                 if (response && response.data) {
  //                   this.router.navigate(['/job-view', this.jobId]);  // Navigate to the job view page
  //                   this.toastr.success('Job Items added and saved successfully!');
  //                 } else {
  //                   this.toastr.error('Failed to save Job Items.');
  //                 }
  //               },
  //               (error) => {
  //                 this.toastr.error('An error occurred while saving the Job Items.');
  //               }
  //             );
  
  //           this.toastr.success('Job Created from Quote');
  //           // window.location.reload(); // Reload after creating the job
  //         } else {
  //           this.toastr.error('Failed to create job from quote');
  //         }
  //       },
  //       (error) => {
  //         this.toastr.error('An error occurred while creating the job.');
  //       }
  //     );
  // }
  async createJobFromRevisionV2(id: string) {
    try {
      const data = {
        id,
        entityType: "revision"
      };
  
      const jobResponse = await this.apiHelper
        .post(data, ApiEndPoints.createJobFromRevision, true)
        .toPromise();
  
      if (jobResponse && jobResponse.data) {
        const newJobId = jobResponse.data.id;
  
        const itemsWithComponentsPromises = this.review.items.map(async (item: any) => {
          const componentResponse = await this.apiHelper
            .post({ id: item.id }, ApiEndPoints.getItemComponents)
            .toPromise();
  
          return {
            ...item,
            components: componentResponse?.data || []
          };
        });
  
        const itemsWithComponents = await Promise.all(itemsWithComponentsPromises);
        
        const jobListData = {
          reviewId: this.reviewId,
          jobItems: itemsWithComponents,
          jobId: newJobId
        };
  
        const jobItemsResponse = await this.apiHelper
          .post(jobListData, ApiEndPoints.createJobItems, true)
          .toPromise();
  
        if (jobItemsResponse && jobItemsResponse.data) {
          this.jobId = newJobId;
          this.toastr.success('Job created successfully with all items and components!');
          this.router.navigate(['/job-view', newJobId]);
        } else {
          throw new Error('Failed to create job items');
        }
      } else {
        throw new Error('Failed to create job from revision');
      }
    } catch (error) {
      console.error('Error creating job:', error);
      this.toastr.error(error instanceof Error ? error.message : 'An error occurred while creating the job');
    }
  }

  previewsQuotes(){
    this.router.navigate(['/quote-revision-view',this.review.quoteId])
   }
   onInputChange(event: any, item: any) {
    const originalQty = event.target.value;
    const originalWidth = event.target.value;
    const originalHeight = event.target.value;

    if (originalQty !== item.qtyy&& originalWidth !== item.width&& originalHeight !== item.height) {
      item.isQtyChanged = true;
      item.isWidthChanged = true;
      item.isHeightChanged = true;
    } else {
      item.isQtyChanged = false;
      item.isWidthChanged = false;
      item.isHeightChanged = false;
    }
    this.updateSquareFeet(item);
  }
  deleteItem(id:string){
    const components = this.selectedItem?.components || [];
    this.apiHelper.post({id:id,components},ApiEndPoints.revisionItemDelete,true).subscribe( (response: any) => {
      if (response && response.data) {
        this.getAllComponetsOfQuote(this.reviewId);
        this.toastr.success('Quantity Deleted successfully!');
  
        this.review.items = this.review.items.filter((item:any) => item.id !== id);console.log(this.review.item)
        if (this.selectedItem && this.selectedItem.id === id) {
          this.selectedItem = null;
        }
      } else {
        this.toastr.error('Failed to Delete the quantity.');
      }
    },
    (error) => {
      this.toastr.error('An error occurred while updating quantity.');
    })
  }
  confirmDelete(itemId: string) {
    if (confirm("Are you sure you want to delete this item?")) {
      this.deleteItem(itemId);
    }
  }
  // unwanted

  createRevision(id: string) {
    this.router.navigate(['/review-quotes-view', id]);
  }
//   openCostModal() {
//     const dialogRef = this.dialog.open(CostModalComponent, {
//       width: '500px',
//       data: {
//         totalMaterialCost:0 ,
//         glassCost: 0,
//         labourCost: 0,
//         expensePercentage: 0,
//       },
//     });
//     dialogRef.afterClosed().subscribe((result) => {
//       if (result) {
//         console.log('Costs Saved:', result);
//       }
//     });
//   }
// }

deleteComponent(component: any) {
  if (confirm('Are you sure you want to delete this component?')) {
    this.apiHelper
      .post({ id: component.id }, ApiEndPoints.deleteComponent)
      .subscribe({
        next: (response: any) => {
         
          if (response && (response.message === 'deleteComponent' || response.success)) {
            
            if (this.selectedItem && this.selectedItem.components) {
              this.selectedItem.components = this.selectedItem.components.filter(
                (c: any) => c.id !== component.id
              );
            }
            
            this.getAllComponetsOfQuote(this.reviewId);
            
            this.toastr.success('Component deleted successfully.');
          } else {
            console.warn('Unexpected response format:', response);
          
            if (this.selectedItem && this.selectedItem.components) {
              this.selectedItem.components = this.selectedItem.components.filter(
                (c: any) => c.id !== component.id
              );
              this.getAllComponetsOfQuote(this.reviewId);
              this.toastr.success('Component deleted successfully.');
            } else {
              this.toastr.error('Failed to delete the component.');
            }
          }
        },
        error: (error) => {
          console.error('Delete component error:', error);
          this.toastr.error('An error occurred while deleting the component.');
        }
      });
  }
}
startEditComponent(component: any) {
  this.editingComponent = component;
  this.tempCount = component.count;
}

cancelEdit() {
  this.editingComponent = null;
}

updateItemComponent(component: any) {
  if (!this.tempCount || this.tempCount < 0) {
    this.toastr.error('Please enter a valid quantity');
    return;
  }

  const updateData = {
    id: component.id,
    count: Number(this.tempCount) // Ensure count is a number
  };



  this.apiHelper.post(updateData, ApiEndPoints.updateItemComponent).subscribe({
    next: (response: any) => {
    
      if (response && response.data && response.data.success) {
   
        component.count = this.tempCount;
        this.toastr.success('Component updated successfully');  
        this.editingComponent = null;
        this.getAllComponetsOfQuote(this.reviewId);
      } else {
        
        console.error('API response error:', response); 
        this.toastr.error(response?.data?.message || 'Failed to update component');  
      }
    }
    
});
}

openCostModal(item: any) {
  const dialogRef = this.dialog.open(CostModalComponent, {
    width: '1000px',
    height:'600px',
    data: {
      item: item, 
      totalCost: item.totalCost,
      totalMaterialCost: item.totalMaterialCost,
      totalLaborCost: item.totalLaborCost,
      totalGlassCost: item.totalGlassCost,
      aOhFactor: item.aOhFactor,
      profitFactor: item.profitFactor,
      gstCost: item.gstCost,
      subTotal: item.subTotal,
      calculatedExpense:item.calculatedExpense
      },
    });
  
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        item.totalCost = result.totalCost;
        item.totalMaterialCost = result.totalMaterialCost;
    item.totalLaborCost = result.totalLaborCost;
    item.totalGlassCost = result.totalGlassCost;
    item.aOhFactor = result.aOhFactor;
    item.profitFactor = result.profitFactor;
    item.gstCost = result.gstCost;
    item.subTotal = result.subTotal;
    item.calculatedExpense = result.calculatedExpense;


        this.saveCostDetails(item);
      }
    });
  }
  
  saveCostDetails(item: any) {
    this.apiHelper.post(item, ApiEndPoints.revisionListUpdate).subscribe(
      (response: any) => {
        if (response && response.data) {
          this.toastr.success('Costs saved successfully!');
        } else {
          this.toastr.error('Failed to save costs.');
        }
      },
      (error) => {
        this.toastr.error('An error occurred while saving costs.');
      }
    );
  }
  getTotalMaterialCost(components: any[]): number {
    return components.reduce((total, comp) => total + (comp.count * comp.rate), 0);
  }
  getAllComponetsOfQuote(id: string) {
    
    this.apiHelper
      .post({ entityId: id }, ApiEndPoints.getAllComponentsEntity, true)
      .subscribe((response) => {
        if (response.data) {
          console.log(response.data);
          this.allComponets = response.data;
        }
      });
  }
}
