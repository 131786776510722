<ng-container  *ngIf="type">
    <div class="card card-body">
      <h4 class="text-app-primary2">{{quoteDetails?.name}}</h4>
        <div class="card-wrap bg-white border border-dark">
          <div class="table-responsive scrollbar">
            <table class="table table-hover table-border table-common" id="tableId">
              <thead class="thead-light">
                <tr>
                  <th>Sl.No</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Quantity</th>
                  <th>Width</th>
                  <th>Height</th>
                  <th>Total Sq.ft</th>
                  <th>Total Cost</th>
                </tr>
              </thead>
              <tbody>
                <tr
                [attr.id]="'pdf' + i"
                  *ngFor = "let item of itemFromQuote;let i = index" 
                >
                <td>{{ i + 1 }}</td>
                <td>{{ item.productInfo?.name || "Empty" }}</td>
                <td>{{ item.description}}</td> 
                <td>{{item.qty}}</td>
                <td>{{item.width}}</td>
                <td>{{item.height}}</td>
                <td>{{item.totalSquareFeet |number :'1.5-5'}}</td>
                <td>{{item.totalCost|currency : 'INR'}}</td>
                </tr>
              </tbody>
            </table>
            <div class="d-flex justify-content-end mb-3">
              <button 
              class="btn brand-btn btn-primary btn-sm me-3"
              (click)="exportToExcel()">Export to Excel
            </button>
              <button
                class="btn brand-btn btn-primary btn-sm me-3"
                (click)="generatePdf()"
                [disabled]="isGeneratingPdf"
              >
                Download-PDF
              </button>
              <button
            type="button"
            class="btn brand-btn bg-red1 text-white ms-2"
            (click)="close()"
          > Cancel</button>
  
            </div>
          </div>
        </div>
        </div>

   
    

</ng-container>

<ng-container *ngIf="types">
  <div class="card card-body">
    <h4 class="text-app-primary2">{{quoteDetails?.name}}</h4>
    <div class="card-wrap bg-white border border-dark">
      <div class="table-responsive scrollbar">
        <table class="table table-hover table-border table-common" id="tableId">
          <thead class="thead-light">
            <tr>
              <th>Sl.No</th>
              <th>Category</th>
              <th>Sub-Category</th>
              <th>Description</th>
              <th>Colour</th>
              <th>Total Count</th>
            </tr>
          </thead>
          <tbody>
            <tr
              [attr.id]="'pdf' + i"
              *ngFor = "let component of allComponets;let i = index" 
            >
              <td>{{ i + 1 }}</td>
              <td>{{ component?.category }}</td>
              <td>{{ component?.subCategory }}</td>
            <td>{{ component?.description }}</td>
              <td>{{ component?.colour }}</td>
              <td>{{ component?.totalCount }}</td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-end mb-3">
          <button
            class="btn brand-btn btn-primary btn-sm me-3"
            (click)="exportToExcelComponets()"
          >
            Export to Excel
          </button>
          <button
            class="btn brand-btn btn-primary btn-sm me-3"
            (click)="generatePdfComponents()"
            [disabled]="isGeneratingPdf"
          >
            Download-PDF
          </button>
          <button
            type="button"
            class="btn brand-btn bg-red1 text-white ms-2"
            (click)="close()"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
