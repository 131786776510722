import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';

interface JsonbObject {
  id: string;
  name: string;
}

interface Job {
  id: string;
  jobName: string;
  quote?: JsonbObject;
  revision?: JsonbObject;
  project?: JsonbObject;
  company?: JsonbObject;
  region?: JsonbObject;
  jobNumber: string;
  status: string;
}

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss']
})
export class JobsComponent {

  jobData: Job[] = [];
  jsonb: Job[] = [];
  searchTerm: string = '';

  currentPage: number = 1;
  itemsPerPage: number = 10;
  totalPages: number = 1;

  constructor(
    public apiHelper: ApiHelper,
    public toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getAllJobs();
  }

  getAllJobs(): void {
    this.apiHelper.post({}, ApiEndPoints.getAllJob).subscribe((response) => {
      if (response && Array.isArray(response.data)) {
        this.jobData = response.data as Job[];
        console.log(this.jobData)
        this.filterJobs();
      } else {
        this.toastr.error('Failed to load all jobs');
      }
    });
  }

  showData(id: string): void {
    this.router.navigate(['/job-view', id]);
  }

  onPageChange(page: number): void {
    this.currentPage = page;
    this.filterJobs();
  }

  filterJobs(): void {
    let filtered = [...this.jobData];
    const normalizedSearchTerm = this.searchTerm.trim().toLowerCase();

    if (this.searchTerm && this.searchTerm.trim()) {
      filtered = filtered.filter(item =>
        this.customFilterFn(normalizedSearchTerm, item.jobName)
      );
    }

    this.totalPages = Math.ceil(filtered.length / this.itemsPerPage);

    this.jsonb = filtered.slice(
      (this.currentPage - 1) * this.itemsPerPage,
      this.currentPage * this.itemsPerPage
    );
  }
  customSortFn(searchTerm: string, a: string, b: string): number {
    const aStartsWith = a.toLowerCase().startsWith(searchTerm);
    const bStartsWith = b.toLowerCase().startsWith(searchTerm);

    if (aStartsWith && !bStartsWith) {
      return -1; // 'a' comes first
    }
    if (!aStartsWith && bStartsWith) {
      return 1; // 'b' comes first
    }
    return a.localeCompare(b);
  }

  customFilterFn(searchTerm: string, item: string): boolean {
    return item.toLowerCase().includes(searchTerm);
  }
}
