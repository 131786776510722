import { Component, OnInit } from '@angular/core';
import { ApiHelper } from 'src/app/core/service/api.helper';
import { ApiEndPoints } from 'src/app/core/constants';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-view-work-plan',
  templateUrl: './view-work-plans.component.html',
  styleUrls: ['./view-work-plans.component.scss']
})
export class ViewWorkPlanComponent implements OnInit {
  jobId!: string;
  workPlans: any[] = [];

  constructor(
    private apiHelper: ApiHelper,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
   
    this.route.paramMap.subscribe((params) => {
      const id = params.get('jobId');
      this.jobId = id || '';
      if (this.jobId) {
        this.getWorkPlans(this.jobId); 
      }
    });
  }

  
  getWorkPlans(jobId: string) {
    this.apiHelper.post({ jobId }, ApiEndPoints.getAllWorkPlans).subscribe(
      (response: any) => {
        if (response && response.data) {
          this.workPlans = response.data;  
        } else {
          this.toastr.error('No work plans found for this job.');
        }
      },
      (error) => {
        this.toastr.error('Failed to load work plans.');
      }
    );
  }
  
}   
