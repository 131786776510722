import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss'],
})
export class ProjectDetailsComponent implements OnInit {

  createQuoteForm!: FormGroup;
  projectId!: string;
  quoteNumber!: string;
  companies: any[] = [];
  regions: any[] = []; 
  filteredRegions: any[] = [];
  reviewDetails :any[]=[]
  // items!: {
  //   projectDetails: {
  //     id: string;
  //     name: string;
  //     clientInfo: {
  //       name: string;
  //       phone: string;
  //       location: string;
  //     };
  //     company: any;
  //     createdBy: string;
  //     isActive: string;
  //     createdAt: string;
  //   };
  //   quoteDetails: Array<{
  //     createdBy: string;
  //     projectId: string;
  //     createdAt: string;
  //     name: string;
  //     id: string;
  //   }>;
  //   clientDetails :{
  //     name: string;
  //       phone: string;
  //       location: string;
        
  //   }
  // };
  items :any
  dialogRef: any;
  constructor(
    private fb: FormBuilder,
    public apiHelper: ApiHelper,
    public toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.createQuoteForm = this.fb.group({
      name: ['', Validators.required],
      quoteNumber: [{ value: '', disabled: true }],
      createdAt: [{ value: this.getCurrentDate(), disabled: true }],
      regionId: ['', Validators.required],
      companyId: ['', Validators.required],
      projectId: ['']
    });
  
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.projectId = id;
      this.getProjectDetails(id);
      this.getAllCompanies();
      this.getAllRegions(); 
      this.getReviewListDetails();
    }
  
    
    this.createQuoteForm.get('companyId')?.valueChanges.subscribe((companyId) => {
      if (companyId) {
        this.getQuoteNumber(companyId);
      }
    });
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  isObject(value: any): boolean {
    return typeof value === 'object';
  }

  getProjectDetails(id: string) {
    this.apiHelper
      .post({ id: id }, ApiEndPoints.getProjectDetails)
      .subscribe((response) => {
        this.items = response.data;
        
        // Call getQuoteNumber after project details are fetched
        if (this.items?.projectDetails?.company?.id) {
          this.getQuoteNumber(this.items.projectDetails.company.id);
        }
      });
  }

  getQuoteNumber(companyId: string) {
    this.apiHelper
      .post({ companyId: companyId }, ApiEndPoints.getQuoteNumber)
      .subscribe((response) => {
        this.quoteNumber = response.data;

       
        this.createQuoteForm.patchValue({
          quoteNumber: this.quoteNumber,
        });
      });
  }

  getAllCompanies(): void {
    this.apiHelper.post({}, ApiEndPoints.getAllCompanies).subscribe((response) => {
      if (response && response.data) {
        this.companies = response.data;
      } else {
        this.toastr.error('Failed to load companies.');
      }
    });
  }

  getAllRegions(): void {
    this.apiHelper.post({}, ApiEndPoints.getAllRegions).subscribe((response) => {
      if (response && response.data) {
        // Map the response to include both region and shortName in a display-friendly format
        this.regions = response.data.map((region: any) => ({
          id: region.id,
          name: `${region.region} (${region.shortName})`,
          searchName: `${region.region} ${region.shortName}`.toLowerCase() // Concatenate and lowercase for easier searching
        }));
        this.filteredRegions = [...this.regions]; // Initialize filteredRegions with all regions initially
      } else {
        this.toastr.error('Failed to load regions.');
      }
    });
  }
  
  onRegionSearch(term: string): void {
    // Filter regions based on the search term
    const lowerTerm = term.toLowerCase();
    this.filteredRegions = this.regions.filter(region =>
      region.searchName.includes(lowerTerm)
    );
  }

  getCurrentDate(): string {
    const today = new Date();
    const year = today.getFullYear();
    const month = ('0' + (today.getMonth() + 1)).slice(-2); 
    const day = ('0' + today.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  onSubmit(): void {
    if (this.createQuoteForm.valid) {
      this.createQuoteForm.value.projectId = this.projectId;
      this.createQuoteForm.value.quoteNumber = this.quoteNumber;
      const newQuote = this.createQuoteForm.value;

      this.apiHelper.post(newQuote, ApiEndPoints.quoteCreate).subscribe(
        (response) => {
          this.toastr.success('Quote created successfully!');
          this.getProjectDetails(this.projectId);
          this.createQuoteForm.reset();
        },
        (error) => {
          this.toastr.error('Failed to create quote.');
        }
      );
    }
  }

  showData(id: string) {
    this.router.navigate(['/quotes-view', id]);
  }
  getReviewListDetails(){
     
      this.apiHelper.post({projectId:this.projectId},ApiEndPoints.reviewDetailsGet,true).subscribe((response)=>{
          this.reviewDetails = response.data;
       
      })
  }
  goToReviewListView(id:string){
    this.router.navigate(['/review-quotes-view',id])
  }
  //
  quoteRevisionView(id: string) {
    this.router.navigate(['/quote-revision-view', id]);
  }
}
