<div class="card card-body">
<div class="card-warp bg-white">

    
       <div class="row mb-3">
        <div class="col-md-2 col-sm-12 search mb-1">
          <input 
            type="text" 
            class="form-control" 
            placeholder="Search by Job Name" 
            [(ngModel)]="searchTerm" 
            (ngModelChange)="filterJobs()"
            aria-label="Search Jobs"
          />
          <i class="fa fa-search ms-2"></i>
        </div>
      </div>

    <div class=" mt-0 table-responsive table-hig">
        <table class="table tbale-hover table-common">
            <thead class ="thead-light">
                <tr>
                    <th scope ="col"> Sl No</th>
                    <th scope ="col">Name</th>
                    <th scope ="col">Qoute</th>
                    <th scope ="col">Revision</th>
                    <th scope ="col">Company</th>
                    <th scope ="col">Region</th>
                    <th scope ="col">Job Number</th>
                    <th scope ="col">Status</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let job of jsonb; let i = index" (click)="showData(job.id)"
                style="cursor: pointer;" class="table-row">
                <td>{{ i+1}}</td>
                <td>{{job.jobName}}</td>
                <td>{{job.quote?.name}}</td>
                <td>{{job.revision?.name}}</td>
                <td>{{job.company?.name}}</td>
                <td>{{job.region?.name}}</td>
                <td>{{job.jobNumber}}</td>
                <td>{{job.status}}</td>
            </tr>
            </tbody>
        </table>
    </div>

    <app-pagination 
    [currentPage]="currentPage" 
    [totalPages]="totalPages" 
    [itemsPerPage]="itemsPerPage" 
    (pageChange)="onPageChange($event)">
  </app-pagination>
</div>
</div>

