import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiHelper } from 'src/app/core/service/api.helper';
import { ApiEndPoints } from 'src/app/core/constants';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

export interface Product {
  id: string;
  productType: string; // formerly c2
  quoteId?: string;
  type: string;
  segment: string; // formerly c1
  description: string; // formerly c3
  productInfo?: {
    code: string;
    name: string;
  };
}

export interface Item {
  productId: string;
  product: Product[];
  type: string;
  count: number;
  quoteId: string;
}

@Component({
  selector: 'app-searchmodal',
  templateUrl: './searchmodal.component.html',
  styleUrls: ['./searchmodal.component.scss'],
})
// export class SearchmodalComponent implements OnInit {
//   searchForm: FormGroup;
//   filteredProducts: any = [];
//   allProducts: any[] = []; // To store all products from the search API
//   segmentOptions: string[] = []; // Available segment values
//   filteredProductTypeOptions: string[] = []; // Filtered productType values based on selected segment
//   selectedItems: Product[] = [];
//   existingItems: Product[] = [];
//   newItems: Item[] = [];
//   quoteId: string = '';
//   //addingType
//   type: string = '';
//   reviewId: string = '';

//   constructor(
//     private fb: FormBuilder,
//     @Inject(MAT_DIALOG_DATA) public data: any,
//     private dialogRef: MatDialogRef<SearchmodalComponent>,
//     private apiHelper: ApiHelper
//   ) {
//     this.searchForm = this.fb.group({
//       selectedSegment: [null],
//       selectedProductType: [null],
//     });
//     this.type = data.type;
//     if (this.type === 'quotesView') {
//       this.quoteId = data.quoteId;
//       this.existingItems = data.selectedItems;
//     }
//     if (this.type === 'reviewQuotesView') {
//       this.reviewId = data.reviewId;
//       this.quoteId = data.id;
//       this.existingItems = data.selectedItems;
//     }

//     // Watch for changes to selectedSegment and update productType options accordingly
//     this.searchForm
//       .get('selectedSegment')
//       ?.valueChanges.subscribe((selectedSegment) => {
//         this.updateProductTypeOptions(selectedSegment);
//       });

//     // Fetch filtered products when productType is selected
//     this.searchForm
//       .get('selectedProductType')
//       ?.valueChanges.pipe(debounceTime(300), distinctUntilChanged())
//       .subscribe((selectedProductType) => {
//         this.filterProductsByProductType(selectedProductType);
//       });
//   }

//   ngOnInit(): void {
//     // Fetch all products when the component initializes
//     this.loadAllProducts();
//   }

//   // Load all products and extract unique segment options
//   loadAllProducts() {
//     this.apiHelper
//       .post({}, ApiEndPoints.search, false)
//       .subscribe((response) => {
//         this.allProducts = response.data;
//         console.log(response.data); // Store all products
//         this.extractSegmentOptions(); // Extract unique segment options
//       });
//   }

//   // Extract unique segment values from the product list
//   extractSegmentOptions() {
//     const segmentSet = new Set<string>();
//     this.allProducts.forEach((product: any) => {
//       segmentSet.add(product.segment); // Add unique segment values
//     });
//     this.segmentOptions = Array.from(segmentSet); // Convert Set to Array
//   }

//   // Filter productType options based on the selected segment value
//   updateProductTypeOptions(selectedSegment: string) {
//     const productTypeSet = new Set<string>();
//     this.allProducts
//       .filter((product: any) => product.segment === selectedSegment)
//       .forEach((product: any) => {
//         productTypeSet.add(product.productType); // Add unique productType values for selected segment
//       });
//     this.filteredProductTypeOptions = Array.from(productTypeSet); // Convert Set to Array
//   }

//   // Filter products based on the selected productType value
//   filterProductsByProductType(selectedProductType: string) {
//     this.filteredProducts = this.allProducts.filter(
//       (product: any) => product.productType === selectedProductType
//     );
//   }

//   // Add product by product.id and product.productType
//   addProduct(productId: string) {
//     const selectedProduct = this.filteredProducts.find(
//       (product: any) => product.id === productId
//     );

//     if (selectedProduct) {
//       const totalOccurrences = this.getProductCount(productId);

//       // Ensure that 'product' is an array
//       const item: Item = {
//         productId: selectedProduct.id,
//         product: [selectedProduct], // Make sure it's an array
//         type: (totalOccurrences + 1).toString(),
//         quoteId: this.quoteId,
//         count: 1,
//       };

//       this.newItems.push(item);
//     }
//   }
 
//   // Remove product by both type and description
//   removeProduct(productToRemove: Product) {
//     this.newItems = this.newItems.filter((item) =>
//       item.product.every(
//         (product) =>
//           product.type !== productToRemove.type ||
//           product.description !== productToRemove.description
//       )
//     );
//   }

//   // Close dialog and send selected products
//   closeDialog() {
//     const data = {
//       quoteId: this.quoteId,
//       productDetails: this.newItems,
//     };

//     this.apiHelper
//       .post(data, ApiEndPoints.quoteListCreate, true)
//       .subscribe((response) => {
//         const selectedProducts = response.data.map((product: any) => ({
//           ...product,
//         }));
//         this.dialogRef.close({ selectedItems: selectedProducts });
//       });
//   }
//   closeDialogV2() {
//     if (this.type === 'quotesView') {
//       const data = {
//         quoteId: this.quoteId,
//         productDetails: this.newItems,
//       };

//       this.apiHelper
//         .post(data, ApiEndPoints.quoteListCreate, true)
//         .subscribe((response) => {
//           const selectedProducts = response.data.map((product: any) => ({
//             ...product,
//           }));
//           this.dialogRef.close({ selectedItems: selectedProducts });
//         });
//     }
//     if (this.type === 'reviewQuotesView') {
//       const data = {
//         revisionId: this.reviewId,
//         productDetails: this.newItems,
//         quoteId: this.quoteId,
//       };

//       this.apiHelper
//         .post(data, ApiEndPoints.revisionListCreateBySearch, true)
//         .subscribe((response) => {
//           const selectedProducts = response.data.map((product: any) => ({
//             ...product,
//           }));
//           this.dialogRef.close({ selectedItems: selectedProducts });
//         });
//     }
//   }
//   getProductCount(productId: string): number {
//     console.log(productId);
//     console.log('existingItem', this.existingItems);
//     const occurrencesInExisting = this.existingItems.filter(
//       (i) => i.id === productId
//     ).length;
//     console.log('ex', occurrencesInExisting);
//     console.log('newItem', this.newItems);
//     const occurrencesInNewItems = this.newItems.filter(
//       (i) => i.productId === productId
//     ).length;
//     console.log('new', occurrencesInNewItems);
//     console.log('total', occurrencesInExisting + occurrencesInNewItems);
//     return occurrencesInExisting + occurrencesInNewItems;
//   }
  
//   //currently Using Function
//   getProductCountV2(productId: string): number {
//     const occurrencesInExisting = this.existingItems.filter(
//       (i) => i.productInfo?.code === productId
//     ).length;

//     const occurrencesInNewItems = this.newItems.filter(
//       (i) => i.productId === productId
//     ).length;

//     return occurrencesInExisting + occurrencesInNewItems;
//   }
//   addProductV2(productId: string) {
//     const selectedProduct = this.filteredProducts.find(
//       (product: any) => product.id === productId
//     );

//     if (selectedProduct) {
//       const totalOccurrences = this.getProductCountV2(productId);
//       // Ensure that 'product' is an array
//       const item: Item = {
//         productId: selectedProduct.id,
//         product: [selectedProduct], // Make sure it's an array
//         type: (totalOccurrences + 1).toString(),
//         quoteId: this.quoteId,
//         count: 1,
//       };

//       this.newItems.push(item);
//     }
//   }
// }


//newone
export class SearchmodalComponent implements OnInit {
  searchForm: FormGroup;
  filteredProducts: any = [];
  allProducts: any[] = []; // To store all products from the search API
  segmentOptions: string[] = []; // Available segment values
  filteredProductTypeOptions: string[] = []; // Filtered productType values based on selected segment
  selectedItems: Product[] = [];
  existingItems: Product[] = [];
  newItems: Item[] = [];
  quoteId: string = '';
  //addingType
  type: string = '';
  reviewId: string = '';
  filteredOptions: string[] =[]
  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SearchmodalComponent>,
    private apiHelper: ApiHelper
  ) {
    this.searchForm = this.fb.group({
      selectedSegment: [null],
      selectedProductType: [null],
    });
    this.type = data.type;
    if (this.type === 'quotesView') {
      this.quoteId = data.quoteId;
      this.existingItems = data.selectedItems;
    }
    if (this.type === 'reviewQuotesView') {
      this.reviewId = data.reviewId;
      this.quoteId = data.id;
      this.existingItems = data.selectedItems;
    }

    // Watch for changes to selectedSegment and update productType options accordingly
    this.searchForm
      .get('selectedSegment')
      ?.valueChanges.subscribe((selectedSegment) => {
        this.updateProductTypeOptions(selectedSegment);
      });

    // Fetch filtered products when productType is selected
    this.searchForm
      .get('selectedProductType')
      ?.valueChanges.pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((selectedProductType) => {
        this.filterProductsByProductType(selectedProductType);
        
      });
  }

  ngOnInit(): void {
    // Fetch all products when the component initializes
    this.loadAllProducts();
  }

  // Load all products and extract unique segment options
  loadAllProducts() {
    this.apiHelper
      .post({}, ApiEndPoints.search, false)
      .subscribe((response) => {
        this.allProducts = response.data;
        this.extractSegmentOptions(); // Extract unique segment options
      });
  }

  // Extract unique segment values from the product list
  extractSegmentOptions() {
    const segmentSet = new Set<string>();
    this.allProducts.forEach((product: any) => {
      segmentSet.add(product.segment); // Add unique segment values
    });
    this.segmentOptions = Array.from(segmentSet); 
    // Convert Set to Array
  }

  // Filter productType options based on the selected segment value
  updateProductTypeOptions(selectedSegment: string) {
    const productTypeSet = new Set<string>();
    this.allProducts
      .filter((product: any) => product.segment === selectedSegment)
      .forEach((product: any) => {
        productTypeSet.add(product.productType); // Add unique productType values for selected segment
      });
    this.filteredProductTypeOptions = Array.from(productTypeSet); // Convert Set to Array

    this.filteredOptions = [...this.filteredProductTypeOptions];

    this.searchForm.get('selectedProductType')?.setValue(null);
    this.filteredProducts = [];
  }

  // Filter products based on the selected productType value
  filterProductsByProductType(selectedProductType: string) {
    this.filteredProducts = this.allProducts.filter(
      (product: any) => product.productType === selectedProductType
    );
    this.filteredOptions = [...this.filteredProductTypeOptions];
  }

  // Add product by product.id and product.productType
  addProduct(productId: string) {
    const selectedProduct = this.filteredProducts.find(
      (product: any) => product.id === productId
    );

    if (selectedProduct) {
      const totalOccurrences = this.getProductCount(productId);

      // Ensure that 'product' is an array
      const item: Item = {
        productId: selectedProduct.id,
        product: [selectedProduct], // Make sure it's an array
        type: (totalOccurrences + 1).toString(),
        quoteId: this.quoteId,
        count: 1,
      };

      this.newItems.push(item);
    }
  }
 
  // Remove product by both type and description
  removeProduct(productToRemove: Product) {
    this.newItems = this.newItems.filter((item) =>
      item.product.every(
        (product) =>
          product.type !== productToRemove.type ||
          product.description !== productToRemove.description
      )
    );
  }

  // Close dialog and send selected products
  closeDialog() {
    const data = {
      quoteId: this.quoteId,
      productDetails: this.newItems,
    };

    this.apiHelper
      .post(data, ApiEndPoints.quoteListCreate, true)
      .subscribe((response) => {
        const selectedProducts = response.data.map((product: any) => ({
          ...product,
        }));
        this.dialogRef.close({ selectedItems: selectedProducts });
      });
  }
  closeDialogV2() {
    if (this.type === 'quotesView') {
      const data = {
        quoteId: this.quoteId,
        productDetails: this.newItems,
      };

      this.apiHelper
        .post(data, ApiEndPoints.quoteListCreate, true)
        .subscribe((response) => {
          
          // const selectedProducts = response.data.map((product: any) => ({
          //   ...product,
          // }));
          this.dialogRef.close({ selectedItems:response.data });
        });
    }
    if (this.type === 'reviewQuotesView') {
      const data = {
        revisionId: this.reviewId,
        productDetails: this.newItems,
        quoteId: this.quoteId,
      };

      this.apiHelper
        .post(data, ApiEndPoints.revisionListCreateBySearch, true)
        .subscribe((response) => {
          // const selectedProducts = response.data.map((product: any) => ({
          //   ...product,
          // }));
          this.dialogRef.close({ selectedItems:response.data });
        });
    }
  }
  getProductCount(productId: string): number {
    const occurrencesInExisting = this.existingItems.filter(
      (i) => i.id === productId
    ).length;
    const occurrencesInNewItems = this.newItems.filter(
      (i) => i.productId === productId
    ).length;
    return occurrencesInExisting + occurrencesInNewItems;
  }
  
  //currently Using Function
  getProductCountV2(productId: string): number {
    const occurrencesInExisting = this.existingItems.filter(
      (i) => i.productInfo?.code === productId
    ).length;

    const occurrencesInNewItems = this.newItems.filter(
      (i) => i.productId === productId
    ).length;

    return occurrencesInExisting + occurrencesInNewItems;
  }
  addProductV2(productId: string) {
    const selectedProduct = this.filteredProducts.find(
      (product: any) => product.id === productId
    );

    if (selectedProduct) {
      const totalOccurrences = this.getProductCountV2(productId);
      // Ensure that 'product' is an array
      const item: Item = {
        productId: selectedProduct.id,
        product: [selectedProduct], // Make sure it's an array
        type: (totalOccurrences + 1).toString(),
        quoteId: this.quoteId,
        count: 1,
      };

      this.newItems.push(item);
    }
  }
  //search in product function
  onSearchChange(event:any) {
    let searchTerm = event.term
    // this.filteredOptions = [...this.filteredProductTypeOptions];
    if (!searchTerm) {
      this.filteredOptions = [...this.filteredProductTypeOptions];
      return;
    }

    searchTerm = searchTerm.toLowerCase(); // Convert to lowercase for case-insensitive matching
  this.filteredOptions = [...this.filteredProductTypeOptions];
    // Filter and sort the options dynamically based on the search term
    this.filteredOptions= this.filteredOptions
      .sort((a, b) => this.customSortFn(searchTerm, a, b))  // Sorting logic
      .filter(item => this.customFilterFn(searchTerm, item));  // Filtering logic
  }

  customSortFn(searchTerm: string, a: string, b: string): number {
    const aStartsWith = a.toLowerCase().startsWith(searchTerm);
    const bStartsWith = b.toLowerCase().startsWith(searchTerm);

    if (aStartsWith && !bStartsWith) {
      return -1;  // 'a' comes first
    }
    if (!aStartsWith && bStartsWith) {
      return 1;  // 'b' comes first
    }
    return a.localeCompare(b);  // If neither starts with the term, sort alphabetically
  }

  customFilterFn(searchTerm: string, item: string): boolean {
    return item.toLowerCase().includes(searchTerm);  // Filter based on inclusion
  }
  onClear(): void {

    this.filteredOptions = [...this.filteredProductTypeOptions];
  }
}
